import { useEffect, useState } from "react"

import './mission.css'

import Colis from "../colis"
import DataMode from "../dataMode"

export default function Mission({ action, colis, handleSubmit, mission })
{
    const [typeVehiculeMission, setTypeVehiculeMission] = useState(mission ? mission.type_vehicule : "")

    useEffect(() => {
        if (mission) {
            setTypeVehiculeMission(mission.type_vehicule)
        }
    }, [mission])

    return <form className="container-mission" onSubmit={ handleSubmit } id="from-create-mission"> 
        <div className="base-info-mission">
            <div className="data-general-mission" id="data-general-mission">
                <h3 className="title-info-mission">Informations Générales</h3>
                <DataMode mode={ action } field={ "Délai" } typeData={ "text" } dataName={ "delai" } data={ mission ? mission.delai : null }/>
                <div className="separation-thick"></div>
                <label className="data-mode">
                    Type Véhicule :
                    {
                        action === 'read' ?
                            <h3>{ mission?.type_vehicule }</h3>
                        :
                        <select  name="type_vehicule" defaultValue={ typeVehiculeMission } value={ typeVehiculeMission } className='select-filter' onChange={(e) => setTypeVehiculeMission(e.target.value)}>
                            <option value="" className='option-filter'>---</option>
                            <option value="Camion Citerne" className='option-filter'>Camion Citerne</option>
                            <option value="Camion Benne" className='option-filter'>Camion Benne</option>
                            <option value="Camion Grue" className='option-filter'>Camion Grue</option>
                            <option value="Camion Semi-remorque" className='option-filter'>Camion Semi-remorque</option>
                            <option value="Camion Frigorifique" className='option-filter'>Camion Frigorifique</option>
                            <option value="Camion Plateau" className='option-filter'>Camion Plateau</option>
                            <option value="Camion Taulier" className='option-filter'>Camion Taulier</option>
                            <option value="Camion Porte Char" className='option-filter'>Camion Porte Char</option>
                            <option value="Camion Baché" className='option-filter'>Camion Baché</option>
                        </select>
                    }
                </label>
                <div className="separation-thick"></div>
                <DataMode mode={ action } field={ "Matériel De Déchargement Nécessaire" } typeData={ "text" } dataName={ "materiel_dechargement" } data={ mission ? mission.materiel_dechargement : null } />
            </div>
            <div className="info-mone" id="info-mone">
                <h3 className="title-info-mission">Tarifs</h3>
                <DataMode mode={ action } field={ "Montant Assurance (0€ si pas d'assurance)" } typeData={ "number" } dataName={ "montant_assurance" } data={ mission ? mission.montant_assurance : null } unite={ "€"}/>
                <div className="separation-thick"></div>
                <DataMode mode={ action } field={ "Prix Fret" } typeData={ "number" } dataName={ "prix" } data={ mission ? mission.prix : null } unite={ "€"}/>
            </div>
        </div>
        <div className="colis-mission">
            {
                colis.map((cls, index) => (
                    <Colis action={ action } infoColis={ cls } number={ index } key={ index }/>
                ))
            }
        </div>
    </form>
}