import Home from './../../pages/home'
import Login from './../../pages/login'
import SignUp from './../../pages/signUp'
import CRUDMission from './../../pages/CRUDMission'
import FindMission from './../../pages/findMission'
import Profil from './../../pages/profile'
import ManageMission from './../../pages/manageMission'
import SuccessPaiementPage from './../../pages/successPaiement'
import Admin from './../../pages/admin'
import ResetPassword from '../../pages/resetPassword'
import PageNotFound from '../../pages/pageNotFound'
import MentionsLegales from '../../pages/mentionsLegales'
import CGVU from '../../pages/CGVU'

export const navigation = [
    { path: "*", name: "404", element: <PageNotFound />, isPrivate: false},
    { path: "/", name: "Accueil", element: <Home />, isPrivate: false },
    { path: "/login", name: "Connexion", element: <Login />, isPrivate: false },
    { path: "/sign-up", name: "Inscription", element: <SignUp />, isPrivate: false },
    { path: "/reset-password", name: "ResetPassword", element: <ResetPassword />, isPrivate: false},
    { path: "/profil/:idUser", name: "Profil", element: <Profil />, isPrivate: true },
    { path: "/find-mission", name: "FindMission", element: <FindMission />, isPrivate: true },
    { path: "/:mode/mission/:idMission", name: "CRUDMission", element: <CRUDMission />, isPrivate: true },
    { path: "/manage-offre-fret/:idMission", name: "ManageMission", element: <ManageMission />, isPrivate: true },
    { path: "/success-paiement", name: "SuccessPaiement", element: <SuccessPaiementPage />, isPrivate: true },
    { path: "/admin-dashboard", name: "Admin", element: <Admin />, isPrivate: true },
    { path: "/mentions-legales", name: "MentionLegales", element: <MentionsLegales />, isPrivate: false},
    { path: "/cgvu", name: "CGVU", element: <CGVU />, isPrivate: false}
]
