import './littleButton.css'

export default function LittleButton({ text, color, background, onClick, valueClick, type='submit' })
{
    const buttonStyle = {
        color: color,
        backgroundColor: background,
    }

    const handleClick = () => {
        if (onClick) {
            onClick(valueClick)
        }
    }


    return <button type={ type } className='little-button'  onClick={handleClick} style={ buttonStyle }>
        { text }
    </button>
}