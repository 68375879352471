import { useEffect, useState } from 'react'

import './findMission.css'

import { AuthData } from "../../utils/auth/AuthWrapper"

import fetchData from "../../utils/function/fetchData"
import Button from '../../components/buttons/button'
import CardMission from '../../components/cards/cardMission'
import MapRender from '../../features/findMission/mapRender'
import LittleButton from '../../components/buttons/littleButton'

export default function FindMission()
{
    const [missions, setMissions] = useState([])

    const [viewMap, setViewMap] = useState(false)

    const [priceSortOrder, setPriceSortOrder] = useState("")
    const [distanceSortOrder, setDistanceSortOrder] = useState("")
    const [dangerFilter, setDangerFilter] = useState("")
    const [truckFilter, setTruckFilter] = useState("")
    const [authorized, setAuthorized] = useState(true)

    const { user } = AuthData()
    
    useEffect(() => {
        console.log('user', user)
        if (user.user.user_metadata.role !== 2) {
            setAuthorized(false)
        } else {
            async function loadData() {
            try {
                if (distanceSortOrder) {
                    navigator.geolocation.getCurrentPosition(success, error)

                    function success(position) {
                        const latitudeUser = position.coords.latitude;
                        const longitudeUser = position.coords.longitude;

                        fetchData({
                            endpoint: `/offre-fret/range`,
                            method: "POST",
                            body: {
                                latitude: latitudeUser,
                                longitude: longitudeUser,
                                distance: distanceSortOrder
                            },
                            user
                        }).then(dataMisions => {
                            dataMisions.filter(mission => mission.fini === false
                            ).filter(mission => {
                                if (mission.offre_fret.some(offre => offre["accepte"] === true)) {
                                    return false
                                } else {
                                    return true
                                }
                            }
                            )
                            setMissions(dataMisions);
                        }).catch(err => {
                            console.error('Error fetching data:', err);
                        });
                    }

                    function error() {
                        console.log('error')
                    }

                } else {

                const dataMisions = await fetchData({
                    endpoint: "/offre-fret/",
                    user
                })
                dataMisions.filter(mission => {
                    if (mission.offre_transport?.some(offre => offre.accepte === true)) {
                        return false
                    } else {
                        return true
                    }
                }
                )
                setMissions(dataMisions)
            }
            } catch (err) {
                console.log("Error:", err);
            }
        }
        loadData()
    }

    }, [user, distanceSortOrder])

    // Only Transporteur can access this page
    if (!authorized) {
        return <div className="container-find-mission">
            <h1 className='title-basic'>Vous n'avez pas accès à cette page.</h1>
            <Button text={ "Retourner à l'accueil" } link='/' />
        </div>
    }


    const getFilteredAndSortedMissions = () => {
        return missions
            .slice()
            .sort((a, b) => {
                if(a.created_at < b.created_at) { return 1; }
                if(a.created_at > b.created_at) { return -1; }
                return 0;
            })
            .sort((a, b) => {
                if (priceSortOrder === 'asc') {
                    return a.prix - b.prix;
                } else if (priceSortOrder === 'desc') {
                    return b.prix - a.prix;
                }
                return 0;
            })
            .filter(mission => {
                if (dangerFilter === 'Produit Dangeureux') {
                    return mission.colis.some(colis => colis["dangereux"] === true);
                } else if (dangerFilter === 'Produit Non-Dangeureux') {
                    return mission.colis.some(colis => colis["dangereux"] === false);
                }
                return true;
            })
            .filter(mission => {
                if (truckFilter) {
                    return mission.type_vehicule === truckFilter ? true : false;
                }
                return true;
            })
            .filter(mission => mission.fini === false)
            .filter(mission => {
                if (mission.offre_fret?.some(offre => offre["accepte"] === true)) {
                    return false
                } else {
                    return true
                }
            })
    }

    const filteredAndSortedMissions = getFilteredAndSortedMissions();
    


    return <div className="container-find-mission">
        <h1 className='title-basic'>Missions Disponibles :</h1>
        <div className="separation-hor"></div>
        <div className="main-find-mission">
            <div className="show-mission">
            {filteredAndSortedMissions.length === 0 ? <h3 className='no-mission-found-title' >Aucunes missions trouvées...</h3> 
                :   viewMap ? (
                    <MapRender missions={filteredAndSortedMissions} />
                )
                    :   (filteredAndSortedMissions.map((mission, index) => (
                        <CardMission key={index} mission={mission} />
                    )
            ))
            }
            </div>
            <div className="separation-ver"></div>
            <div className="filter-section">
                <h1 className='title-basic'>Filtres :</h1>
                {/* <label className='choice-filtre'>
                    Voir uniquement les missions qui respectent mon coût/Km
                    <input type='checkbox' className='check-box'/>
                </label> */}
                <label className='choice-filtre'>
                    Prix
                    <select value={ priceSortOrder } onChange={(e) => setPriceSortOrder(e.target.value) } className='select-filter'>
                        <option value="" className='option-filter'>---</option>
                        <option value="desc" className='option-filter'>Ordre Décroissant</option>
                        <option value="asc" className='option-filter'>Ordre Croissant</option>
                    </select>
                </label>
                <label className='choice-filtre'>
                    Distance
                    <select value={ distanceSortOrder } onChange={(e) => setDistanceSortOrder(e.target.value) } className='select-filter'>
                        <option value="" className='option-filter'>---</option>
                        <option value="50" className='option-filter'>50km</option>
                        <option value="100" className='option-filter'>100km</option>
                        <option value="150" className='option-filter'>150km</option>
                        <option value="200" className='option-filter'>200km</option>
                    </select>
                </label>
                <label className='choice-filtre'>
                    Type Camion 
                    <select name="truck-filter" value={ truckFilter } onChange={(e) => setTruckFilter(e.target.value) } className='select-filter'>
                        <option value="" className='option-filter'>---</option>
                        <option value="Camion Citerne" className='option-filter'>Camion Citerne</option>
                        <option value="Camion Benne" className='option-filter'>Camion Benne</option>
                        <option value="Camion Grue" className='option-filter'>Camion Grue</option>
                        <option value="Camion Semi-remorque" className='option-filter'>Camion Semi-remorque</option>
                        <option value="Camion Frigorifique" className='option-filter'>Camion Frigorifique</option>
                        <option value="Camion Plateau" className='option-filter'>Camion Plateau</option>
                        <option value="Camion Tautliner" className='option-filter'>Camion Tautliner</option>
                        <option value="Camion Porte Char" className='option-filter'>Camion Porte Char</option>
                        <option value="Camion Baché" className='option-filter'>Camion Baché</option>
                    </select>
                </label>
                <label className='choice-filtre'>
                    Marchandise
                    <select name="danger-filter" value={ dangerFilter } onChange={ (e)=>setDangerFilter(e.target.value)} className='select-filter'>
                        <option value="" className='option-filter'>---</option>
                        <option value="Produit Dangeureux" className='option-filter'>Produit Dangeureux</option>
                        <option value="Produit Non-Dangeureux" className='option-filter'>Produit Non-Dangeureux</option>
                    </select>
                </label>
                { viewMap ? 
                    <LittleButton text={ "Voir Sur Liste" } onClick={ setViewMap }  valueClick={ !viewMap }/> 
                    : <LittleButton text={ "Voir Sur Carte" } onClick={ setViewMap } valueClick={ !viewMap }/>
                }
            </div>
        </div>
    </div>
}