import './cardAvis.css'

import LogoReload from '../../../assets/LogoReload.jpg'

import StarRate from '../../starRate'
import { useEffect, useState } from 'react'

import fetchData from '../../../utils/function/fetchData'
import { AuthData } from '../../../utils/auth/AuthWrapper'

export default function CardAvis({ avis }) {

    const [reviewWritter, setReviewWritter] = useState({})
    const { user } = AuthData()

    useEffect(() => {
        const reviewWritter = fetchData({
            endpoint: `/profile/${avis.created_by}`,
            user
        })
        reviewWritter.then((user) => {
            setReviewWritter(user)
        })
    
    }, [avis])

    return <div className='card-mission-profile-review'>
        <img src={LogoReload} alt="logo-reload" className='logo-reload-card-mission-profile' />
        <div className="card-mission-profile-info-review">
            <StarRate rating={avis.note} />
            <p>Par { reviewWritter.prenom } { reviewWritter.nom }: "{avis.message}"</p>
        </div>
    </div>
}