import "./home.css"

// import scrollBaseAnimation from "../../features/home/scrollBaseAnimation";
import '../../features/home/scrollBaseAnimation/scrollBasedAnimation.css'

import { AuthData } from "../../utils/auth/AuthWrapper"

import { FcClock, FcRuler, FcMoneyTransfer   } from "react-icons/fc";
import { FaClipboardList, FaTruck, FaBoxes  } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import { MdManageAccounts } from "react-icons/md";
import { TbCubeSend } from "react-icons/tb";

import textHome from "../../data/textHomePage.json"
import truckImg from "../../assets/truck.png"
import paletteImg from "../../assets/palette.png"

import TitleHome from "../../components/titleHome"
import TextHome from "../../components/textHome"
import Button from "../../components/buttons/button"
import CardHome from "../../components/cards/cardHome"

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'; // Import ScrollTrigger plugin
import { useGSAP } from "@gsap/react";
import Lenis from "@studio-freight/lenis";

export default function Home()
{
    const { user } = AuthData()

    useGSAP(() => {

        const lenis = new Lenis();

        lenis.on("scroll", (e) => {

        });

        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }

        requestAnimationFrame(raf);

        gsap.registerPlugin(ScrollTrigger);
        // gsap code here...
        gsap.to(".section-2-1", {
            scrollTrigger: {
                trigger: ".section-2-1",
                start: "top center",
                end: "40% center",
                scrub: true,
                markers: false,
            },
            x: 2500,
            duration: 4
        });
        gsap.to(".section-2-2", {
            scrollTrigger: {
                trigger: ".section-2-2",
                start: "top center",
                end: "40% center",
                scrub: 1,
                markers: false
            },
            x: -2500,
            duration: 4
        });
        gsap.to(".section-3-1", {
            scrollTrigger: {
                trigger: ".section-3-1",
                start: "top center",
                end: "40% center",
                scrub: true,
                markers: false
            },
            x: 2500,
            duration: 4
        });
        gsap.to(".section-3-2", {
            scrollTrigger: {
                trigger: ".section-3-2",
                start: "top center",
                end: "40% center",
                scrub: 1,
                markers: false
            },
            x: -2500,
            duration: 4
        });
        gsap.to(".section-4-1", {
            scrollTrigger: {
                trigger: ".section-4-1",
                start: "top center",
                end: "40% center",
                scrub: true,
                markers: false
            },
            x: 2500,
            duration: 4
        });
        gsap.to(".section-4-2", {
            scrollTrigger: {
                trigger: ".section-4-2",
                start: "top center",
                end: "40% center",
                scrub: true,
                markers: false
            },
            x: -2500,
            duration: 4
        });
    });

    return <div className="home-container">
                <section className="section-1-1">
                    <TitleHome title={ textHome.section1.title } className='test-section'/>
                    <div className="section-1-1-main">
                        <img src={ truckImg } className="home-img" alt="Camion de transport" id="test"/>
                        <div className="section-1-2-main">
                            <TextHome text={ textHome.section1.textScene11 }/>
                            <TextHome text={ textHome.section1.textScene12 }/>
                            <Button text={ "Rejoindre L'Aventure" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                        <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                    </div>
                    <h3 className="scroll">--Scroll--</h3>
                </section>
                <section className="section-2-1" id="section-2">
                    <TitleHome title={ textHome.section2.title } className='section2-title'/>
                    <div className="section-2-1-1-main">
                        <div className="img-btn-home">
                            <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                            <Button text={ "Déposer Une Offre" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                        <TextHome text={ textHome.section2.textScene21 }/>
                    </div>
                    <h3 className="scroll">--Scroll--</h3>
                </section>
                <section className="section-2-2">
                    <TitleHome title={ textHome.section2.title }/>
                    <div className="section-2-2-1-main">
                        <div className="bento-home-container">
                            <h3 className="title-bento">Les Avantages</h3>
                            <div className="container-bento-card">
                                <CardHome text={ textHome.section2.carte1 } icon={ <FaBoxes size={50} color="white" className="icon-home-card"/> } />
                                <CardHome text={ textHome.section2.carte2 } icon={ <SlLocationPin size={50} color="white" className="icon-home-card"/> } />
                                <CardHome text={ textHome.section2.carte3 } icon={ <FaTruck size={50} color="white" className="icon-home-card"/> } />
                            </div>
                        </div>
                        <div className="img-btn-home">
                            <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                            <Button text={ "Déposer Une Offre" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                    </div>
                </section>
                <section className="section-3-1" id="section-3">
                    <TitleHome title={ textHome.section3.title }/>
                    <div className="section-3-1-1-main">
                        <div className="img-btn-home">
                            <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                            <Button text={ "Commencer" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                        <TextHome text={ textHome.section3.textScene31 }/>
                    </div>
                    <h3 className="scroll">--Scroll--</h3>
                </section>
                <section className="section-3-2">
                    <TitleHome title={ textHome.section3.title }/>
                    <div className="section-3-2-1-main">
                        <div className="bento-home-container">
                            <h3 className="title-bento">Les Avantages</h3>
                            <div className="container-bento-card">
                                <CardHome text={ textHome.section3.carte1 } icon={ <MdManageAccounts size={50} color="white" className="icon-home-card"/> }/>
                                <CardHome text={ textHome.section3.carte2 } icon={ <FaClipboardList size={50} color="white" className="icon-home-card"/> } />
                                <CardHome text={ textHome.section3.carte3 } icon={ <TbCubeSend size={50} color="white" className="icon-home-card"/> }/>
                            </div>
                        </div>
                        <div className="img-btn-home">
                            <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                            <Button text={ "Commencer" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                    </div>
                </section>
                <section className="section-4-1" id="section-4">
                    <TitleHome title={ textHome.section4.title }/>
                    <div className="section-4-1-1-main">
                        <div className="img-btn-home">
                            <img src={ truckImg } className="home-img" alt="Camion de transport"/>
                            <Button text={ "Trouver Une Offre" } link={ user ? `/profil/${user.user.id}` : "/login" } />
                        </div>
                        <TextHome text={ textHome.section4.textScene41 }/>
                    </div>
                    <h3 className="scroll">--Scroll--</h3>
                </section>
                <section className="section-4-2">
                    <TitleHome title={ textHome.section4.title }/>
                    <div className="section-4-1-1-main">
                        <div className="bento-home-container">
                            <h3 className="title-bento">Les Avantages</h3>
                            <div className="container-bento-card">
                                <CardHome text={ textHome.section4.carte1 } icon={ <FcClock size={50} color="white" className="icon-home-card"/> } />
                                <CardHome text={ textHome.section4.carte2 }  icon={ <FcRuler size={50} color="white" className="icon-home-card"/> }/>
                                <CardHome text={ textHome.section4.carte3 }  icon={ <FcMoneyTransfer size={50} color="white" className="icon-home-card"/> }/>
                            </div>
                        </div>
                        <div className="img-btn-home">
                            <img src={ paletteImg } className="home-img" alt="Chargement de colis de transport"/>
                            <Button text={ "Trouver Une Offre" } link={ user ? `/profil/${user.user.id}` : "/login" }/>
                        </div>
                    </div>
                </section>
            </div>
}