import "./login.css"

import Button from "../../components/buttons/button"

import truckImg from "../../assets/truck.png"

import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

import { AuthData } from "../../utils/auth/AuthWrapper"
import PasswordReset from "../../features/login/passwordReset"

export default function Login() 
{
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { user } = AuthData()
    const { login } = AuthData()

    useEffect(() => {
        if (user) {
            return (
                navigate('/')
            )
        }
    }, [navigate, user])

    const handleSubmit = async (event) => {
        try {
            await login(event)
        } catch (error) {
            setError(error.message)
        }
    }

    return <div className="container-login">
        <img src={ truckImg } className="home-img" alt="Camion de transport"/>
        <div className="separation-ver"></div>
        <div className="container-form-login-reset-password">
            <form className="main-login" onSubmit={ handleSubmit } id="form-login">
                <h1 className="title-basic">CONNEXION</h1>
                <label className="information-login">
                    Email
                    <input 
                        type="email" 
                        name="email"
                        required
                    />
                </label>
                <label className="information-login">
                    Mot De Passe
                    <input 
                        type="password" 
                        name="password"
                        required
                    />
                </label>
                <p className="intivite-sign-up">Pas encore de compte ? Cliquez <Link to="/sign-up"><p className="text-link-sign-up"> ici</p></Link></p>
                { error && <p>{ error }</p>}
            </form>
            <PasswordReset />
            <Button text={ "Connexion" } typeButton={ "submit" } form={ "form-login" }/>
        </div>
    </div>
}