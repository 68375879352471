
async function fetchData({ endpoint, method = 'GET', body = null, user }) {

    const apiUrl = process.env.REACT_APP_API_URL
    const url = `${apiUrl}${endpoint}`

    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user.auth_token}`
    }

    if (endpoint.split('/').includes('document')) {
      headers['Content-Type'] = 'application/pdf';
    }

    const config = {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : null
    }


    try {
        const response = await fetch(url, config);

        if (!response.ok) {
          throw new Error(`API call failed with status: ${response.status}`)
        }

        const text = await response.text(); // Read response body as text
        if (!text) {
            return null; // No content
        }

        // Safely parse the text as JSON
        return JSON.parse(text);


        // return await response.json()

      } catch (error) {
        if (error.statusCode === 401) {
          console.error('Fetch aborted')
          window.location.href = '/login';
        } else if (error.statusCode === 404) {
          console.error('Resource not found')
          window.location.href = '/404';
        } else {
          console.error('Error fetching data:', error.message)
          throw error
        }
      }
}

export default fetchData