import './manageTruck.css'

import fetchData from '../../../utils/function/fetchData'

import { useEffect, useState } from 'react'
import { Toaster, toast } from 'sonner'

import bigTruck from '../../../assets/bigTruck.svg'
import LittleButton from "../../../components/buttons/littleButton"
import CRUDTruck from './CRUDTruck'
import CardVehicule from '../../../components/cards/cardVehicule'

export default function ManageTruck( { society, user } ) {   
    const [trucks, setTrucks] = useState([])

    const [createTruck, setCreateTruck] = useState(false)

    useEffect(() => {
        async function loadData() {
          try {

            const result = await fetchData({
              endpoint: `/vehicule`,
              user
            })
            const truckSociety = result.filter(item => item.societe.id === user.user.user_metadata.societe)
            setTrucks(truckSociety)

          } catch (err) {
            console.log(err)
          }
        }
    
        loadData()
    }, [createTruck, user])

    return <div className="container-manage-carrier">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <div className="container-manage-carrier-header">
            <h2 className='title-manage-carrier'>Gerer les vehicules: </h2>
            <div className="separation-hor"></div>
        </div>
        <div className="container-manage-carrier-body">
            <LittleButton text={ "Ajouter Vehicule" } valueClick={ true } onClick={ setCreateTruck }/>
            <div className="separation-ver"></div>
            <div className="container-manage-truck-card">
            {trucks.length === 0 ? (
                    <p className='info-data-profile-carrier' style={{ textAlign: "center", padding: "2%" }}>Aucun vehicule lié à la société pour le moment...</p>
                ) : (
                    trucks.map((item, index) => (
                        <CardVehicule key={index} vehicule={item} />
                    ))
                )}
            </div>
        </div>
        { createTruck && <div className='manage-truck' style={{display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '2%'}}>
            <CRUDTruck societe={ society } user={ user } setCreateTruck={ setCreateTruck }/>
        </div> 
        }
    </div>
}