import { useStripe, PaymentElement, useElements, LinkAuthenticationElement } from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import { useState, useEffect } from "react"

export default function CheckoutForm ({ user, idMission })
{
    const stripe = useStripe()
    const elements = useElements()

    const emailUser = user.email
    const [email, setEmail] = useState(emailUser)
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    console.log('idMission', idMission)
    window.localStorage.setItem('idMisison', idMission)
  
    useEffect(() => {

        if(!stripe){
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

        if(!clientSecret){
            return
        }

        stripe.retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'requires_payment_method':
                    setMessage('Votre paiement a échoué, veuillez réessayer avec une autre carte')
                    break
                case 'succeeded':
                    setMessage('Votre paiement a été effectué avec succès')
                    break
                default:
                    setMessage('Une erreur est survenue lors du paiement, veuillez réessayer plus tard')
                    break
            }
        })

    }, [stripe])

    const handleSubmit = async (event) => {
        event.preventDefault()
        setIsLoading(true)

        if (!stripe || !elements) {
            setIsLoading(false)
            return
        }

        const baseURL = process.env.REACT_APP_BASE_URL
        // If it's in production mode, we use the production URL
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // return_url: 'http://localhost:3000/success-paiement',
                return_url: `https://reload-goods.fr/success-paiement`
            }
        })

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message)
        } else {
            setMessage('Une erreur est survenue lors du paiement, veuillez réessayer plus tard')
            
        }

        setIsLoading(false)
    }

    const handleEmailChange = (e) => {
        e.preventDefault()

        setEmail(e.target.value)
    }


    const paiementOptions = {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#9e2146',
            },
        },
        layout: 'tabs',
    }


    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <LinkAuthenticationElement  
                id='link-authentication-element'
                onChange={ setEmail }
            />
            <PaymentElement id='payment-element' options={paiementOptions} />
            <div className="container-pay-btn" style={{display:'flex', justifyContent:'center'}}>
                <button className="main-button" type='submit' disabled={isLoading || !stripe || !elements} id='submit'>
                    <span id='button-text'>
                        {isLoading ? <div className='spinner' id='spinner'></div> : 'Payer'}
                    </span>
                </button>
            </div>
            {message && <div>{message}</div>}
        </form>
    )
}