import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import { Marker } from 'react-leaflet/Marker'
import { Popup } from 'react-leaflet/Popup'
import { useEffect, useState } from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";
import { Icon } from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import "leaflet/dist/leaflet.css";
import './mapRender.css'

import { AuthData } from '../../../utils/auth/AuthWrapper'
import fetchData from '../../../utils/function/fetchData'


export default function MapRender({ missions }) {
    const { user } = AuthData()
    const [userLocation, setUserLocation] = useState(null)
    const [markers, setMarkers] = useState([])
    const customIcon = new Icon({
        iconUrl: 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png',
        iconSize: [38, 38]
    })


    useEffect(() => {

        navigator.geolocation.getCurrentPosition(success, error)

        function success(position) {
            const latitudeUser = position.coords.latitude;
            const longitudeUser = position.coords.longitude;
            setUserLocation([latitudeUser, longitudeUser])
        }

        function error() {
            console.log('error')
        }

        const fetchMarkers = async () => {
            const promises = missions.map(async (mission) => {
                if (mission.colis[0]?.adresse_recuperation) {
                    const dataAdresseRecuperation = await fetchData({
                        endpoint: `/adresse/${mission.colis[0]?.adresse_recuperation}`,
                        method: "GET",
                        user
                    });
                    const marker = {
                        "geocode": [dataAdresseRecuperation.latitude, dataAdresseRecuperation.longitude],
                        "link":  `/read/mission/${mission.id}`,
                        "data": mission.colis.length 
                    };
                    return marker;
                }else {
                    const marker = {
                        "geocode": [48.8566, 2.3522],
                        "popUp": "Adresse de récupération null"
                    };
                    return marker;
                }
            });

            const resolvedMarkers = await Promise.all(promises);
            setMarkers([...resolvedMarkers]);
        };

        fetchMarkers();
    }, [missions, user]);

    return <MapContainer center={ userLocation ? userLocation : [48.8566, 2.3522] } zoom={13} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup>
                {markers.length !== 0 && markers.map((marker, index) => (
                    <Marker position={marker?.geocode} key={ index } icon={customIcon}>
                        <Popup>
                            <a href={marker?.link}>Voir La Mission</a>
                            <p>Nombre d'unité : { marker.data} coli(s)</p>
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
        </MapContainer>

}