import './colis.css'

import Adresse from "../adresse"
import DataMode from "../dataMode"

export default function Colis( { action, infoColis=null, number } ) 
{
    const id = `${ number }`
    
    return <div className="container-colis" id={ id }>
        <h2 className="title-info-mission">Information colis n°{ number + 1 }</h2>
        <div className="data-colis">
            <DataMode mode={ action } field={ "Nature Marchandise" } typeData={ "text" } dataName={ `nature-${number}` } data={ infoColis?.nature}/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Poids Colis" } typeData={ "text" } dataName={ `poids-${number}` } data={ infoColis?.poids} unite={ "Kg" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Colis Dangeureux" } typeData={ "checkbox" } dataName={ `dangereux-${number}` } data={ infoColis?.dangereux} unite={ "OUI" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Récupérer Emballage" } typeData={ "checkbox" } dataName={ `recuperer_emballage-${number}` } data={ infoColis?.recuperer_emballage} unite={ "OUI" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Nombre Emballage A Rendre" } typeData={ "number" } dataName={ `nombre_emballage-${number}` } data={ infoColis?.nombre_emballage} />
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Hauteur Colis" } typeData={ "number" } dataName={ `hauteur-${number}` } data={ infoColis?.hauteur} unite={ "mètre(s)" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Longueur Colis" } typeData={ "number" } dataName={ `longueur-${number}` } data={ infoColis?.longueur} unite={ "mètre(s)" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Largeur Colis" } typeData={ "number" } dataName={ `largeur-${number}` } data={ infoColis?.largeur} unite={ "mètre(s)" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Volume Colis" } typeData={ "number" } dataName={ `volume-${number}` } data={ infoColis?.volume} unite={ "mètre(s) cube" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Mètre Plancher" } typeData={ "number" } dataName={ `metre_plancher-${number}` } data={ infoColis?.metre_plancher} unite={ "mètre(s)" }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Colis Gerbable" } typeData={ "checkbox" } dataName={ `gerbable-${number}` } data={ infoColis?.gerbable} unite={ "OUI" }/>
        </div>
        <div className="data-colis-adresse">
            <Adresse action={ action } type= { "chargement" } number={ number } adresseColis={ infoColis?.adresse_recuperation}/>
            <Adresse action={ action } type= { "dechargement" } number={ number } adresseColis={ infoColis?.adresse_livraison}/>
        </div>
        
    </div>
}