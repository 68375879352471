import { useState } from "react"

import fetchData from '../../../../utils/function/fetchData'
import LittleButton from "../../../../components/buttons/littleButton";
import { Toaster, toast } from "sonner";
export default function CRUDTruck ({ societe, user, setCreateTruck })
{
    const [typeTruck, setTypeTruck] = useState({})
    const handleCreateTruck = (e) => {
        e.preventDefault()
        const form = e.target
        const formData = new FormData(form)
        
        const truck = {
            "type_vehicule": typeTruck,
            "immatriculation": formData.get("immatriculation"),
            "societe": societe.id,
        }

        const truckCreated = fetchData({  
            endpoint: `/vehicule`,
            method: "POST",
            body: truck,
            user
        })

        truckCreated
            .then(dataTruckCreated => {
                console.log("dataTruckCreated", dataTruckCreated)
                toast.success('Véhicule créé')
                setCreateTruck(false)
            })
            .catch(error => {
                toast.error('Erreur lors de la création du véhicule')
                console.error(error)
            })
    }

    return <>
        <Toaster position='top-center' richColors closeButton={ true }/>
        <form onSubmit={ (e) => handleCreateTruck(e) }>
            <label className='data-mode'>
                Immatriculation:
                <input type="text" name="immatriculation" />
            </label>
            <label className='data-mode'>
                Type de véhicule:
                <select  name="type_vehicule" value={ typeTruck } className='select-filter' onChange={(e) => setTypeTruck(e.target.value)}>
                    <option value="" className='option-filter'>---</option>
                    <option value="Camion Citerne" className='option-filter'>Camion Citerne</option>
                    <option value="Camion Benne" className='option-filter'>Camion Benne</option>
                    <option value="Camion Grue" className='option-filter'>Camion Grue</option>
                    <option value="Camion Semi-remorque" className='option-filter'>Camion Semi-remorque</option>
                    <option value="Camion Frigorifique" className='option-filter'>Camion Frigorifique</option>
                    <option value="Camion Plateau" className='option-filter'>Camion Plateau</option>
                    <option value="Camion Taulier" className='option-filter'>Camion Taulier</option>
                </select>
            </label>
            <label className='data-mode'>
                Société:
                <input type="text" value={societe.nom} />
            </label>
            <LittleButton type="submit" text="Créer Véhicule"/>
            <LittleButton type="submit" onClick={ setCreateTruck } valueClick={ false } text="Annuler"/>
        </form>
    </>
}
