import "./signUp.css"

import truckImg from "../../assets/truck.png"
import paletteImg from "../../assets/palette.png"

import Button from "../../components/buttons/button"

import { useEffect, useState } from "react"

import { AuthData } from "../../utils/auth/AuthWrapper"

export default function SignUp()
{
    const [error, setError] = useState("")

    const { user, signUp } = AuthData()


    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const validateFileSize = (file) => {
        if (file.size > MAX_FILE_SIZE) {
            alert('Fichier trop volumineux. Taille maximum : 5Mo');
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        const password = formData.get("password");
        const passwordConfirmation = formData.get("password-confirmation");
        if (password !== passwordConfirmation) {
            setError("Les mots de passe ne correspondent pas");
            return;
        }
        if (password.length < 8) {
            setError("Le mot de passe doit contenir au moins 8 caractères");
            return;
        }

        const profileData = {
            "nom": formData.get("nom"),
            "prenom": formData.get("prenom"),
            "email": formData.get("email"),
            "telephone": formData.get("telephone"),
            "password": formData.get("password"),
        };

        if (role !== 3) {
            const fileInput = document.getElementById('fileInput');
            const file = fileInput.files[0];
            profileData["certificat"] = file
        }

        if (role === 2 || role === 1) {
            const file = profileData["certificat"]
            if (!validateFileSize(file)) return; // Validate file size

            if (role === 2) {
                // Additional fields for transporteur
                profileData["numero_siret"] = formData.get("numero_siret");
                profileData["numero_tva"] = formData.get("numero_tva");
                profileData["type_marchandise"] = formData.get("type_marchandise").split(",");
            } else if (role === 1) {
                // Additional fields for admin
                profileData["nom_societe"] = formData.get("nom_societe");
                profileData["telephone_referant_societe"] = formData.get("telephone_referant_societe");
                profileData["mail_referant_societe"] = formData.get("mail_referant_societe");
                profileData["iban"] = formData.get("iban");
            }

            const formDataProfile = new FormData();
            for (const key in profileData) {
                if (Array.isArray(profileData[key])) {
                    profileData[key].forEach(item => formDataProfile.append(`${key}[]`, item));
                } else {
                    formDataProfile.append(key, profileData[key]);
                }
            }

            try {
                formDataProfile.forEach((value, key) => {
                    console.log(key, value)
                })
                await signUp(formDataProfile, role === 2 ? "transporteur" : "admin", role === 2 ? user : null);
            } catch (error) {
                console.error('Error during sign up:', error);
            }
        } else {
            signUp(profileData, "client");
        }
    };



    const [role, setRole] = useState(1)

    /**
     * Only Manager can create a carrier account
     */
    useEffect(() => {
        if ( user ) {
            if (user.user.user_metadata.role === 1) {
                setRole(2)
            }
        }
    }, [user])

    const checkManager = () => {
        if (user) { 
            if ( user.user.user_metadata.role === 1) {
                setRole(2)
            }
        }
    }

    return <div className="container-sign-up">
        <div className="img-sign-up">
            <img src={ truckImg } alt="Camion de transport" className="home-img" />
            <img src={ paletteImg } alt="Chargement de colis" className="home-img"/>
        </div>
        <div className="separation-ver"></div>
        <form className="main-sign-up" onSubmit={ (e) => handleSubmit(e) } encType="true" method="post" action="/upload" enctype="multipart/form-data">
            <h1 className="title-basic">CREATION DE COMPTE</h1>
            <h2 className="sub-title-sign-up">Type De Compte :</h2>
            <label className="information-sign-up">
                Compte Gérant Société
                <input 
                    type="checkbox" 
                    checked={role === 1}
                    onChange={() => setRole(1)}
                    className="check-box"
                />
                Compte Expéditeur
                <input 
                    type="checkbox" 
                    checked={role === 3}
                    onChange={() => setRole(3)}
                    className="check-box"
                />
                Compte Chauffeur (créer par un gérant)
                <input 
                    type="checkbox" 
                    checked={role === 2}
                    onChange={() => checkManager()}
                    className="check-box"
                />
            </label>
            <h2 className="sub-title-sign-up">Information De Base :</h2>
            <label className="information-sign-up">
                Nom 
                <input 
                    type="text"
                    name="nom"
                    required
                />
            </label>
            <label className="information-sign-up">
                Prénom
                <input 
                    type="text"
                    name="prenom"
                    required
                />
            </label>
            <label className="information-sign-up">
                Email
                <input 
                    type="email"
                    name="email"
                    required
                />
            </label>
            <label className="information-sign-up">
                Téléphone
                <input 
                    type="tel"
                    name="telephone"
                    required
                />
            </label>
            <label className="information-sign-up">
                Mot De Passe
                (8 caractères minimum)
                <input 
                    type="password"
                    name="password"
                    required
                />
            </label>
            <label className="information-sign-up">
                Mot De Passe Confirmation
                <input 
                    type="password"
                    name="password-confirmation"
                    required
                />
            </label>
            {role === 2 && <div className="transport-part">
                        <h2 className="sub-title-sign-up">Informations Chauffeur :</h2>
                        <label className="information-sign-up">
                            Numero Siret
                            <input 
                                type="text"
                                name="numero_siret"
                                required
                            />
                        </label>
                        <label className="information-sign-up">
                            Numero TVA
                            <input 
                                type="text"
                                name="numero_tva"
                                required
                            />
                        </label>
                        <label className="information-sign-up">
                            Certificat Instruction
                            <input 
                                type="file"
                                name="certificat_instruction"
                                required
                                id="fileInput"
                            />
                        </label>                        
                        <label className="information-sign-up">
                            Marchandises Transportées (séparées par une virgule)
                            <input 
                                type="text"
                                name="type_marchandise"
                            />
                        </label>
                        {/* <label className="information-sign-up">
                            Coût / Km
                            <input type="number" required/>
                        </label> */}
                    </div>
            }
            { role === 1 && <div className="gerant-societe-part">
                <h2 className="sub-title-sign-up">Informations Gérant Société :</h2>
                <label className="information-sign-up">
                    Nom Société
                    <input 
                        type="text"
                        name="nom_societe"
                        required
                    />
                </label>
                <label className="information-sign-up">
                    Téléphone Référent
                    <input 
                        type="tel"
                        name="telephone_referant_societe"
                        required
                    />
                </label>
                <label className="information-sign-up">
                    Email Référent
                    <input 
                        type="email"
                        name="mail_referant_societe"
                        required
                    />
                </label>
                <label className="information-sign-up">
                    Certificat Immatriculation K Bis
                    <input 
                        type="file"
                        name="certificat_kbis"
                        required
                        id="fileInput"
                    />
                </label>
                <label className="information-sign-up">
                    Iban
                    <input 
                        type="text"
                        name="iban"
                        required
                    />
                </label>
            </div>
            }
            <label className="information-sign-up">
                    J'accepte <a href="/cgvu" style={{color:'white'}}>les conditions d'utilisation</a>
                    <input 
                        type="checkbox"
                        required
                    />
                </label>
            <h2 style={{ color: "#FFF" }}>{error}</h2>
            <Button text={ "Créer Compte" } typeButton={ "submit" }/>
        </form>
    </div>
}

