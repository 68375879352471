import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast, Toaster } from "sonner"

import rightArrow from "../../../assets/rightArrowIcon.svg"
import './menuMission.css'


import LittleButton from "../../../components/buttons/littleButton"

import { AuthData } from "../../../utils/auth/AuthWrapper"
import fetchData from "../../../utils/function/fetchData"

import CreateOffreTransport from "./../createOffreTransport"
import BackButton from "./../../../components/buttons/backButton"


export default function MenuMission({ mission, colis, setColis, mode, userCreation }) {

    const { user } = AuthData()

    const navigate = useNavigate()

    // Create Mission Part:
    const addColis = () => {
        setColis(currentColis => [...currentColis, {}])
        document.querySelector(".menu-create-update-mission").style.top = `${30 - (colis.length -1) * 5}vh`
    }

    const deleteColis = () => {
        if ( colis.length > 1) {
            const updatedColis = colis.slice(0, -1)
            setColis(updatedColis)
            document.querySelector(".menu-create-update-mission").style.top = `${30 - (colis.length ) * 5}vh`
        }
    }

    // Offre transport Part:
    const [negotiate, setNegotiate] = useState(false)

    const handleSubmitNegociate = (e) => {
        e.preventDefault()
        const price = e.target["prix-negociate"].value

        CreateOffreTransport({
            idOffreFret: mission.id,
            price,
            user
        })
    }

    // Update Mission Part:
    // const [deletedColis, setDeletedColis] = useState(0)

    // const deleteUpdatedColis = () => {
    //     if ( colis.length > 1) {
    //         const updatedColis = colis.filter((cls, index) => index !== deletedColis - 1)
    //         setColis(updatedColis)
    //     }
    // }

    // Delete Mission Part:
    const deleteMission = () => {
            fetchData({
                endpoint: `/offre-fret/${mission.id}`,
                method: "DELETE",
                user
            })
                .then(() => {
                toast.success('Offre de fret supprimée')
                navigate(`/profil/${user.user.sub}`)
                })
                .catch((error) => {
                    toast.error('Erreur lors de la suppression de l\'offre de fret')
                    console.error(error)
                })
    }


    
    const renderMenu = () => {
        switch (mode) {
            case "create":
                return <div className="menu-create-mission-param">
                    <div className="manage-colis-btn-menu">
                        Nombre de colis : { colis?.length }
                        <button className="little-button"  type="click"  onClick={addColis}>Ajouter Colis</button>
                        <button className="little-button" type="button" onClick={() => deleteColis()}>Supprimer Colis</button>
                    </div>
                    <div className="separation-thick"></div>
                    <div className="create-mission-btn-menu">
                        <button className="little-button" type="submit" form="from-create-mission">Créer Offre Fret</button>
                        <button className="little-button" type="button" onClick={() => navigate('/')}>Annuler Offre Fret</button>
                    </div>
                </div>
            case "update":
                return <div className="menu-create-mission-param">
                    <div className="manage-colis-btn-menu">
                        Nombre de colis : { colis.length }
                        {/* <button className="little-button"  type="click"  onClick={addColis}>Ajouter Colis</button> */}
                    </div>
                    <div className="separation-thick"></div>
                    {/* <div className="create-mission-btn-menu">
                        <select value={ deletedColis } onChange={ (e) => setDeletedColis(e.target.value) }>
                            <option value={ 0 }>---</option>
                                {colis.map((cls, index) => 
                                    <option key={index} value={index + 1}>Colis n°{index + 1}</option>)
                                }
                        </select>
                        <button className="little-button" type="button" onClick={() => deleteUpdatedColis()}>Supprimer Colis n°{ deletedColis }</button>
                    </div>
                    <div className="separation-thick"></div> */}
                    <div className="create-mission-btn-menu">
                        <button className="little-button" type="submit" form="from-create-mission">Sauvegarder Modification</button>
                        <button className="little-button" type="button" onClick={() => navigate(`/read/mission/${mission?.id}`)}>Annuler Modification</button>
                    </div>
                </div>
            case "read":
                const defaultReadMenu = <>
                    <p>Créer par: {userCreation?.nom } {userCreation?.prenom }</p>
                    <div className="separation-thick"></div>
                    <p>Créer le: { new Date(mission?.created_at).toLocaleDateString() }</p>
                    <div className="separation-thick"></div>
                    <p>Adresse mail: {userCreation?.mail }</p>
                    <div className="separation-thick"></div>
                    <p>Téléphone: {userCreation?.telephone }</p>
                    <div className="separation-thick"></div>
                </>
                if ( user.user.sub === userCreation?.id) {
                    return <div className="menu-create-mission-param">
                        { defaultReadMenu }
                        <div className="mission-btn-read-mode">
                            <LittleButton text={ "Mofifier Offre Fret" } color="#FFF" background="green" onClick={ navigate } valueClick={ `/update/mission/${mission.id}` }/>
                            <LittleButton text={ "Supprimer Offre Fret" } color="#FFFFFF" background="#2040A7" onClick={ deleteMission } valueClick={ true }/>
                        </div>
                    </div>
                } else if ( user.user.user_metadata.role === 2 ) {
                    return <div className="menu-create-mission-param-test">
                        { defaultReadMenu }
                        <div className="mission-btn-read-mode">
                            <LittleButton text={ "Accepter Offre" } color="#FFF" background="green" onClick={ CreateOffreTransport } valueClick={ {idOffreFret: mission.id, price: mission.prix, user} }/>
                            <LittleButton text={ "Négocier Offre" } color="#FFFFFF" background="#2040A7" onClick={ setNegotiate } valueClick={ true }/>
                        </div>
                    </div>
                } else {
                    return <div className="menu-create-mission-param">
                        { defaultReadMenu } 
                    </div>
                }
            default:
                break;
        }
    }

    return (
        <div className="menu-create-update-mission">
            <Toaster position='top-center' richColors closeButton={ true }/>
            <BackButton />
            <h2>Menu Offre de Fret:</h2>
            <a href="#data-general-mission" className="btn-menu-create-update-mission"><img src={ rightArrow } alt="rightArrow" className="right-arrow-menu-create"/>Informations Générales</a>
            <div className="separation-thick"></div>
            <a href="#info-mone" className="btn-menu-create-update-mission"><img src={ rightArrow } alt="rightArrow" className="right-arrow-menu-create"/>Tarifs</a>
            <div className="separation-thick"></div>
            {colis?.map((cls, index) => (<div key={ index }>
                <a href={`#${index}`} className="btn-menu-create-update-mission"><img src={ rightArrow } alt="rightArrow" className="right-arrow-menu-create"/>Colis n°{index + 1}</a>
                <div className="separation-thick"></div>
            </div>
            ))}
            { renderMenu() }
            {negotiate && (<form className="mission-btn-read-mode" onSubmit={ handleSubmitNegociate }>
                <label>
                    Proposition Nouveau Prix :
                    <input
                        type="number"
                        name="prix-negociate"
                    />
                    €
                </label>
                <div className="btn-negociate-menu">
                    <LittleButton text={ "Envoyer" } color="#FFFFFF" background="#98369B"/>
                    <LittleButton text={ "Annuler" } color="#FFFFFF" background="#98369B" onClick={ setNegotiate } valueClick={ false }/>
                </div>
            </form>)
            }
    </div>
    )
}