import './manageCarrier.css'

import fetchData from '../../../utils/function/fetchData'

import { useEffect, useState } from 'react'

import CardTransporteur from '../../../components/cards/cardTransporteur'
import Button from '../../../components/buttons/button'

export default function ManageCarrier( { society, user } ) {   
    const [carriers, setCarriers] = useState([])

    useEffect(() => {
        async function loadData() {
          try {

            const result = await fetchData({
              endpoint: `/profile/societe/${society.id}`,
              user
            })
            setCarriers(result)

          } catch (err) {
            console.log(err);
          } finally {
          }
        }
    
        loadData();
    }, [society.id, user])

    return <div className="container-manage-carrier">
        <div className="container-manage-carrier-header">
            <h2 className='title-manage-carrier'>Gerer les chauffeurs: </h2>
            <div className="separation-hor"></div>
        </div>
        <div className="container-manage-carrier-body">
            <Button text={ "Ajouter" } link="/sign-up"/>
            <div className="separation-ver"></div>
            <div className="container-manage-carrier-card">
                {carriers.length === 0 ? (
                    <p className='info-data-profile-carrier' style={{ textAlign: "center", padding: "2%" }}>Aucun transporteur lié à la société pour le moment...</p>
                ) : (
                    carriers.map((item, index) => (
                        <CardTransporteur key={index} transporteur={item} />
                    ))
                )}
            </div>
        </div>
    </div>
}
