import './cardMission.css'

import logoReload from '../../../assets/LogoReload.jpg'

import Button from '../../buttons/button'

export default function CardMission ( mission )
{
    const { id, prix, type_vehicule, created_at, montant_assurance, colis } = mission.mission

    const url = `/read/mission/${id}`

    // const accepterOffre = () => {
    //     const offreTransportNegocie = {
    //         "prix": prix,
    //         "offre_fret": id,
    //         "transporteur": user.user.transporteur,
    //         "accepte": false
    //     }

    //     const offreTransportNegocieCreated = fetchData({
    //         endpoint: "/offre-transport",
    //         method: "POST",
    //         body: offreTransportNegocie,
    //         user
    //     })

    //     offreTransportNegocieCreated
    //         .then(dataOffreTransportNegocieCreated => {
    //             console.log("offreTransportNegocieCreated", dataOffreTransportNegocieCreated)
    //             toast.success('Offre de transport créée')
    //             navigate(`/profil/${user.user.sub}`)
    //         })
    //         .catch(error => {
    //             toast.error('Erreur lors de la création de l\'offre de transport')
    //             console.error(error)
    //         })
    // }

    return <div className="container-card-mission">
            <img src={ logoReload } alt="logo reload" className='card-mission-logo-reload'/>
            <div className="info-mission">
                <div className="logo-reload-title-mission">
                    <h3 className='title-mission'>Mission pour : { prix } €</h3>
                </div>
                <ul className="info-mission-responsive">
                    <li>Type de véhicule requis: { type_vehicule }</li>
                    <li>Nombre d'unité: {colis.length } coli(s)</li>
                    <li>Postée le { new Date(created_at).toLocaleDateString() }</li>
                </ul>
            </div>
            <div className='btn-mission'>
                <Button link={ url } text={ "Voir la mission" }/>
            </div>
        </div>
}