import fetchData from "../../../utils/function/fetchData"

export default function UpdateMission( { event, user, missionUpdate }){
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)
    
    const missionDataUpdated = {
        "delai": formData.get("delai"),
        "type_vehicule": formData.get("type_vehicule"),
        "montant_assurance": formData.get("montant_assurance"),
        "prix": formData.get("prix"),
    }

    try {

        fetchData({
            endpoint: `/offre-fret/${missionUpdate.id}`,
            method: "PATCH",
            body: missionDataUpdated,
            user
        })

        for (let i = 0; i < missionUpdate.colis.length; i++) {
            const colisDataUpdated = {
                "nature": formData.get(`nature-${i}`),
                "poids": formData.get(`poids-${i}`),
                "dangereux":formData.get(`dangereux-${i}`) === "on" ? true : false,
                "recuperer_emballage": formData.get(`recuperer_emballage-${i}`) === "on" ? true : false,
                "hauteur": formData.get(`hauteur-${i}`),
                "longueur": formData.get(`longueur-${i}`),
                "largeur": formData.get(`largeur-${i}`),
                "volume": formData.get(`volume-${i}`),
                "metre_plancher": formData.get(`metre_plancher-${i}`),
                "gerbable": formData.get(`gerbable-${i}`) === "on" ? true : false,
            }

            // Adresse de chargement modification
            const adresseChaObject = {
                label: JSON.parse(formData.get(`label-chargement-${i}`)),
            }
            const adresseChargementDataUpdated = {
                "adresse": adresseChaObject.label.properties.label,
                "complement": formData.get(`precision-chargement-${i}`),
                "nom_responsable": formData.get(`nom_responsable-chargement-${i}`),
                "telephone_responsable": formData.get(`telephone-chargement-${i}`),
                "mail_responsable": formData.get(`mail-chargement-${i}`),
                "date": new Date(formData.get(`date-chargement-${i}`) + " " + formData.get(`horraire-chargement-${i}`)).toISOString(),
                "prise": true,
                "latitude": adresseChaObject.label.geometry.coordinates[1],
                "longitude": adresseChaObject.label.geometry.coordinates[0]
            }
            fetchData({  
                endpoint: `/adresse/${missionUpdate.colis[i].adresse_recuperation}`,
                method: "PATCH",
                body: adresseChargementDataUpdated,
                user
            })
            
            // Adresse de déchargement modification
            const adresseDechaObject = {
                label: JSON.parse(formData.get(`label-dechargement-${i}`)),
            }
            const adresseDechargementData = {
                "adresse": adresseDechaObject.label.properties.label,
                "complement": formData.get(`precision-dechargement-${i}`),
                "nom_responsable": formData.get(`nom_responsable-dechargement-${i}`),
                "telephone_responsable": formData.get(`telephone-dechargement-${i}`),
                "mail_responsable": formData.get(`mail-dechargement-${i}`),
                "date": new Date(formData.get(`date-dechargement-${i}`) + " " + formData.get(`horraire-dechargement-${i}`)).toISOString(),
                "prise": false,
                "latitude": adresseDechaObject.label.geometry.coordinates[1],
                "longitude": adresseDechaObject.label.geometry.coordinates[0]
            }
            fetchData({
                endpoint: `/adresse/${missionUpdate.colis[i].adresse_livraison}`,
                method: "PATCH",
                body: adresseDechargementData,
                user
            })

            // Colis modification
            fetchData({
                endpoint: `/colis/${missionUpdate.colis[i].id}`,
                method: "PATCH",
                body: colisDataUpdated,
                user
            })
        }

    } catch (error) {

        console.error(error)

    }
}