import './profileMR.css'

import fetchData from "../../utils/function/fetchData"

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'sonner'

import StarRate from '../starRate'
import CardOffreFretHistorique from '../cards/cardOffreFretHistorique'
import CardAvis from '../cards/cardAvis'
import CardOffreTransportHistorique from '../cards/cardOffreTransportHistorique'


export default function ProfileMR ( { userViewed, user } )
{
    const [missions, setMissions] = useState([]) 
    const [reviews, setReviews] = useState([])
    const [view, setView] = useState('mission')

    const authorization = userViewed.id === user.user.sub ? true : false

    let avgNotetransporteur = 0

    useEffect(() => {
        async function loadData() {
          try {
            if ( userViewed?.type_marchandise ) {

                const allMissisons = fetchData({
                    endpoint: `/offre-transport`,
                    user
                })
                allMissisons.then((missions) => {
                    const missionsUser = missions.filter(mission => mission.transporteur === userViewed.id)
                    const misisonsUserSorted = missionsUser.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    setMissions(misisonsUserSorted)
                })
                const allReviews = fetchData({
                    endpoint: `/avis`,
                    user
                })
                allReviews.then((avis) => {
                    const avisUser = avis.filter(avis => avis.transporteur === userViewed.id)
                    setReviews(avisUser)
                })
            } else {
                const allMissions = fetchData({
                    endpoint: `/offre-fret`,
                    user
                })
                allMissions.then((missions) => {
                    const missionsUser = missions.filter(mission => mission.created_by === userViewed?.id)
                    const misisonsUserSorted = missionsUser.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    setMissions(misisonsUserSorted)
                })
            }

          } catch (err) {
            console.log("Error finding MRData:", err);
          } finally {
          }
        }
    
        loadData();
    }, [userViewed, user])

    reviews.forEach(review => {
        avgNotetransporteur += review.note
    })
    avgNotetransporteur = avgNotetransporteur / reviews.length

    return <div className="mission-review-profil">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <div className="nav-info-profil">
            {authorization && <button onClick={ () => setView('mission') } className='btn-user-info-nav' style={{ background: view === 'mission' ? 'grey' : 'white' }}>Historique des offres de transport</button>}
            {userViewed.type_marchandise && <button onClick={ () => setView('review') } className='btn-user-info-nav' style={{ background: view === 'review' ? 'grey' : 'white' }}>Avis</button>}
        </div>
        <div className="mission-review-user">
            {(view === 'mission' && authorization) && <div className="mission-review-user-mission-list-item">
                {missions.length === 0 ? (
                        <p style={{textAlign: "center", padding: "2%"}}>Aucune offre pour le moment...</p>
                    ) : userViewed.certificat_instruction ? (
                        missions.map((mission, index) => (
                            <CardOffreTransportHistorique key={index} offre={mission} />
                        ))
                    ) : (
                        missions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                            .map((mission, index) => (
                            <CardOffreFretHistorique key={index} offre={mission} />
                        ))
                    )
                }
            </div>
            } 
            {view === 'review' && 
                <div className="mission-review-user-review">
                    {reviews.length === 0 ? (
                            <p style={{ textAlign: "center", padding: "2%" }}>Aucun avis pour le moment...</p>
                        ) : (
                            <>
                                <div className="mission-review-user-avg-note">
                                    Note Moyenne: <StarRate rating={avgNotetransporteur} />
                                </div>
                                <div className="mission-review-user-review-list-item">
                                    {reviews.map((review, index) => (
                                        <CardAvis key={index} avis={review} />
                                    ))}
                                </div>
                            </>
                        )
                    }
                </div>
            }
        </div>
    </div>
}

