import { useState } from 'react';
import { Toaster, toast } from 'sonner';
import { useNavigate } from 'react-router-dom';

import { supabase } from '../../services/supabase/supabaseClient';

import truckImg from '../../assets/truck.png';

import Button from '../../components/buttons/button';


export default function ResetPassword() {

    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        const password = e.target.password.value;
        const passwordConfirm = e.target.passwordConfirm.value;

        if (password !== passwordConfirm) {
            toast.error('Les mots de passe ne correspondent pas')
            setError('Les mots de passe ne correspondent pas')
            return
        }

        try {

            const { resetData, error } = await supabase.auth.updateUser({ 
                password: password
            });
            if (resetData) {
                setError(null);
                toast.success('Mot de passe changé');
                navigate('/login')
            }
            if (error) {
                throw new Error(error.message);
            }

        } catch (error) {
            setError(error.message);
            toast.error(error.message);
        }
    }

    return <div className="container-login">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <img src={ truckImg } className="home-img" alt="Camion de transport"/>
        <div className="separation-ver"></div>
        <form className="main-login" onSubmit={ handleSubmit }>
            <h1 className="title-basic">Changement de Mot de Passe</h1>
            <label className="information-login">
                Entrer votre nouveau mot de passe
                <input 
                    type={ showPassword ? "text" : "password" }
                    name="password"
                    required
                />
            </label>
            <label className="information-login">
                Confirmer votre nouveau mot de passe
                <input 
                    type={ showPassword ? "text" : "password" }
                    name="passwordConfirm"
                    required
                />
            </label>
            <button onClick={ () => setShowPassword(!showPassword) } className="show-password-btn" type='button'>Afficher les mots de passes</button>
            <Button text={ "Confirmer" } typeButton={ "submit" }/>
            { error && <p>{ error }</p>}
        </form>
    </div>
}
