import './starRate.css'

import { FaStar } from 'react-icons/fa'

import { useState } from 'react'

export default function StarRate({ rating = null }) {
    const [hover, setHover] = useState(null);
    const [rate, setRate] = useState(rating);

    return <div className='container-star-rate'>
            {[...Array(5)].map((star, index) => (
                rating !== null ? (
                    <FaStar
                        key={index}
                        className="star"
                        color={index + 1 <= rate ? '#ffc107' : '#e4e5e9'}
                        size={40}
                    />
                ) : (
                    <label key={index}>
                        <input
                            type="radio"
                            name="rating"
                            value={index + 1}
                            onClick={() => setRate(index + 1)}
                            style={{ display: 'none' }} 
                        />
                        <FaStar
                            className="star"
                            color={index + 1 <= (hover || rate) ? '#ffc107' : '#e4e5e9'}
                            size={40}
                            onMouseEnter={() => setHover(index + 1)}
                            onMouseLeave={() => setHover(null)}
                        />
                    </label>
                )
            ))}
        </div>
}

