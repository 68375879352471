import { useState, useEffect } from "react";

import './findAdresse.css'

import submitAdresseCallAPI from "../../../services/adresseGouv/fecthAPIAdresseGouv"

export default function FindAdresse( { nameAdresse, updateAdresse=null })
{
    const [inputAdresseAPICall, setInputAdresseAPICall] = useState('')
    const [resultsAddresse, setResultsAdresse] = useState([])
    const [inputValueAdresse, setInputValueAdresse] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (updateAdresse) {
            setInputValueAdresse({
                "properties": {
                    "label": updateAdresse[0],
                },
                "geometry": {
                    "coordinates": [updateAdresse[1], updateAdresse[2]]
                }
            })
            setResultsAdresse([{
                "properties": {
                    "label": updateAdresse[0],
                },
                "geometry": {
                    "coordinates": [updateAdresse[1], updateAdresse[2]]
                }
            }])
        }
    }, [updateAdresse])

    return <div className="container-find-adresse">
            <h3 className="container-find-adresse-title">Adresse</h3>
            <div className="container-find-adresse-content">
                <div className="call-api-adresse-form">
                    <input 
                        type="text"
                        name="inputAdresseAPICall"
                        placeholder="Entrer Une Adresse..."
                        value={ inputAdresseAPICall }
                        onChange={(e) => setInputAdresseAPICall(e.target.value)}
                    />
                    <button className="little-button" type="button" onClick={ () =>submitAdresseCallAPI(inputAdresseAPICall, setResultsAdresse, setIsLoading, setError) }>Rechercher Adresse</button>
                </div>
                <div className="result-api-adresse">
                    {isLoading && <p>Chargement...</p>}
                    {error && <p>Erreur: {error}</p>}
                    {(resultsAddresse.length === 0)?
                        <p>Aucun résultat</p>
                        :<select 
                            name={ nameAdresse } 
                            value={ JSON.stringify(inputValueAdresse) } 
                            onChange={ (e) => setInputValueAdresse(JSON.parse(e.target.value)) }
                        >
                        {resultsAddresse.map((result, index) => (
                            <option key={ index } value={ JSON.stringify(result) }>
                                { result.properties.label }
                            </option>
                        ))}
                    </select>                    
                    }
                </div>
            </div>
    </div>
}