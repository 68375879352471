import { Children } from 'react'
import './cardHome.css'

export default function CardHome({ text, icon }) 
{
    return <div className="container-home-card">
        <div className="icon-card-container">
            { icon }
            { icon }
        </div>
        <div className="text-card-container">
            <p>{ text }</p>
        </div>
    </div>
}