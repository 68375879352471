import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';

import fetchData from '../../../../utils/function/fetchData';

import CheckoutForm from '../checkoutForm';

import './paiement.css';

export default function Paiement({ id_offre_transport, user }) {
    const stripePromise = loadStripe(process.env.REACT_APP_API_KEY_STRIPE);
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        fetchData({
            endpoint: `/stripe/checkout/${id_offre_transport}`,
            method: 'POST',
            user
        }).then((data) => {
            setClientSecret(data.client_secret);
        });
    }
    , []);

    const appearance = {
        theme: "stripe",
    }

    const options = {
        appearance,
        clientSecret
    }


    return (
        <div className='paiement-container'>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm user={ user } idMission={ id_offre_transport } />
                </Elements>
            )}
        </div>
    );
}