import "./footer.css"

export default function Footer() 
{
    return <div className="container-footer">
        <div className="separation"></div>
        <ul className="list-footer">
            <li className="list-element">Pour toutes questions ou préoccupations, merci de contacter fboubaker@free.fr</li>
            <li className="list-element">© 2023 Reload. Tous droits réservés.</li>
            <li className="list-element"><a href="/cgvu" className='link-footer'>Conditions Générales de Vente et d'Utilisation</a></li>
            <li className="list-element"><a href="/mentions-legales" className='link-footer'>Mentions Légales</a></li>
        </ul>
    </div>
}