import { toast } from 'sonner'

import fetchData from '../../../utils/function/fetchData'

export default function CreateOffreTransport( { user, idOffreFret, price }) {


    const offreTransport = {
        "prix": price,
        "offre_fret": idOffreFret,
        "transporteur": user.user.sub,
        "accepte": false
    }
    const offreTransportCreated = fetchData({
        endpoint: "/offre-transport",
        method: "POST",
        body: offreTransport,
        user
    })

    offreTransportCreated.then(() => {

        window.location.href = `/profil/${user.user.sub}`
        toast.success('Offre de transport créée')

    }).catch(error => {
        toast.error('Erreur lors de la création de l\'offre de transport')
        console.error(error)
    })
}