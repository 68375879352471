import { useState, createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'sonner'

import parseJwt from './../function/parseJwt'

import Header from './../../components/header'
import Footer  from './../../components/footer'
import RenderRoutes from '../structure/Rendernavigation'


const AuthContext = createContext()

export const AuthData = () => useContext(AuthContext)

export const AuthWrapper = () => {
    
    const [user, setUser] = useState(null)

    const navigate = useNavigate()
        
    const apiUrl = process.env.REACT_APP_API_URL
    const ANON_KEY = process.env.REACT_APP_ANON_KEY

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'))
        if (storedUser) {
            const now = Math.floor(Date.now() / 1000);
            const exp = storedUser.user.exp
            if (now > exp) {
                localStorage.removeItem('user')
                setUser(null)
                return navigate('/')
            }else{
                setUser(storedUser)
            }
        }
    }, [navigate])

    const signUp = async (profile, typeProfile, user=null) => {
        const auth_token = user ? user.auth_token : ANON_KEY
        const headers = new Headers({
            'Authorization': 'Bearer ' + auth_token,
            // Do not set 'Content-Type' for FormData. The browser will set it.
        });
    
        let bodyProfile;
        if (typeProfile === "admin" || typeProfile === "transporteur") {
            // For 'admin', use FormData
            bodyProfile = profile
        } else {
            // For other types, use JSON
            headers.append('Content-Type', 'application/json');
            bodyProfile = JSON.stringify(profile);
        }
    
        try {
            const response = await fetch(`${apiUrl}/profile/${typeProfile}`, {
                method: 'POST',
                headers: headers,
                body: bodyProfile,
                user: user?.auth_token
            });
            // Handle the response
            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message || 'An error occurred');
            }

            toast.success('Inscription réussi')
            return (
                navigate('/login')
            )

        } catch (error) {
            if(error.message === "User already registered"){
                toast.error('Utilisateur déjà inscrit')
                return (
                    navigate('/sign-up')
                )
            }else{
                toast.error('Inscription échouée')
                console.error('Error:', error);
                return (
                    navigate('/sign-up')
                )
            }
        }
    };

    const login = async (event) => {
        event.preventDefault()

        const email = event.target.email.value
        const password = event.target.password.value

        try {
            const headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + ANON_KEY,
            })
            const response = await fetch(`${apiUrl}/supabase/auth`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ email, password })
            })

            const data = await response.json()

            if (!response.ok) {
                throw new Error(data.message || 'An error occurred');
            }
            
            const decodedJwt = parseJwt(data.access_token)
            setUser({"auth_token":data.access_token, "user" : decodedJwt})
            localStorage.setItem('user', JSON.stringify({"auth_token":data.access_token, "user" : decodedJwt}))
            toast.success('Connection réussi')
            if (decodedJwt.user_metadata?.role === 0) {
                navigate('/admin-dashboard')
            }else {
                navigate('/')
            }
        } catch (error) {
            toast.error('Connection échouée')
            return (
                navigate('/login')
            )
        }
    }

    const logout = () => {
        setUser(null)
        toast.success('Déconnection réussi')
        localStorage.removeItem('user')
        navigate('/')
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, signUp }}>
            <>
                <Header />
                <Toaster position='top-center' richColors closeButton={ true }/>
                <RenderRoutes />
                <Footer />
            </>
        </AuthContext.Provider>
    )
}
