import { Link } from "react-router-dom"

import { AuthData } from "../../utils/auth/AuthWrapper"

import logoReload from "../../assets/LogoReload.jpg"

import "./header.css"

import Button from "../buttons/button"

export default function Header() {

    const  { logout }  = AuthData()
    const  { user }  = AuthData()

    const urlProfil = user ? `/profil/${user.user.sub}` : null



    const logoutHandle = () => {
        logout()
    }

    const rendernavBar = () => {
        if (user) {
            switch (user?.user.user_metadata?.role) {
                case 0:
                    return (
                        <div className="container-navigation">
                            <Link to="/" className="link-button">Accueil</Link>
                            <Link to="/admin-dashboard" className="link-button">DashBoard</Link>
                            <button className="main-button" onClick={() => logoutHandle()} type="button"><span>Déconnection</span></button>
                        </div>
                    )
                case 1:
                    return (
                        <div className="container-navigation">
                            <Link to="/" className="link-button">Accueil</Link>
                            <Link to="/create/mission/0" className="link-button">Déposer Offre De Fret</Link>
                            <Link to={urlProfil} className="link-button">Profil</Link>
                            <button className="main-button" onClick={() => logoutHandle()} type="button"><span>Déconnection</span></button>
                        </div>
                    )
                case 2:
                    return (
                        <div className="container-navigation">
                            <Link to="/" className="link-button">Accueil</Link>
                            <Link to="/find-mission" className="link-button">Consulter Offres De Frets</Link>
                            <Link to={urlProfil} className="link-button">Profil</Link>
                            <button className="main-button" onClick={() => logoutHandle()} type="button"><span>Déconnection</span></button>
                        </div>
                    )
                case 3:
                    return (
                        <div className="container-navigation">
                            <Link to="/" className="link-button">Accueil</Link>
                            <Link to="/create/mission/0" className="link-button">Déposer Offre De Fret</Link>
                            <Link to={urlProfil} className="link-button">Profil</Link>
                            <button className="main-button" onClick={() => logoutHandle()} type="button"><span>Déconnection</span></button>
                        </div>
                    )
                default:
                    return null
            }
        } else {
            return (
                <div className="container-navigation">
                    <a href="/#section-2" className="link-button">Informations Expéditeur</a>
                    <a href="/#section-3" className="link-button">Informations Gérant Société</a>
                    <a href="/#section-4" className="link-button">Informations Chauffeur</a>
                    <Button text={"Connexion"} link={"/login"} />
                </div>
            )
        }
    }

    return (
        <div className="nav-bar">
            <img src={logoReload} className="logo" alt="Logo Reload"/>
            { rendernavBar() }
        </div>
    )
}
