import { Link } from "react-router-dom"
import "./button.css"

export default function Button( {link=null, color="#FFFFFF", background="#2040A7", text, logo=null, typeButton="button", form=null} )
{
    const buttonStyle = {
        color: color,
        backgroundColor: background,
    }
    const contentButton = <>
        { text }
    </>

    if( link ) 
    {
        return <Link to={ link } className="main-button-link" type="button">
            <span>{ contentButton }</span>
        </Link>
    } else
    {
        return <button className="main-button" type={ typeButton } style={ buttonStyle } form={ form }> 
            <span>{ contentButton }</span>
        </button>
    }
}