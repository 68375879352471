import { Route, Routes } from "react-router-dom"

import { AuthData } from './../auth/AuthWrapper'

import { navigation } from "./Navigation"


export default function RenderRoutes ()
{

    const { user } = AuthData()

    return (
        <Routes>
            { navigation.map((r, index) => {
                if ( r.isPrivate && user ) {
                    return (
                        <Route 
                            key={ index }
                            path={ r.path }
                            element={ r.element }
                        />
                    )
                } else if ( !r.isPrivate ) {
                    return (
                        <Route 
                            key={ index }
                            path={ r.path }
                            element={ r.element }
                        />
                    )
                }else {
                    return false
                }
            })}
        </Routes>
    )
}