import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import './profil.css'

import profilIcon from '../../assets/profilIcon.png'
import settingIcon from '../../assets/settingIcon.svg';

import fetchData from "../../utils/function/fetchData"

import { AuthData } from "../../utils/auth/AuthWrapper"

import ProfileMR from '../../components/profileMR'
import UpdateData from '../../components/updateData'
import ManageCarrier from '../../features/profile/manageCarrier'
import LittleButton from '../../components/buttons/littleButton'
import ManageTruck from '../../features/profile/manageTruck';

import { Toaster } from 'sonner';
import BackButton from '../../components/buttons/backButton';

export default function Profil() 
{
    const { idUser } = useParams()

    const [userViewed, setUserViewed] = useState({})
    const [update, setUpdate] = useState(null)

    const navigate = useNavigate()

    const { user } = AuthData()

    
    useEffect(() => {
        async function loadData() {
          try {

            const result = await fetchData({
              endpoint: `/profile/${idUser}`,
              user
            })
            setUserViewed(result)

          } catch (err) {
            console.log("Error:", err);
          }
        }
    
        loadData()
    }, [idUser, user])

    // const deleteAccount = () => {
    //     fetchData({
    //         endpoint: `/profile/${idUser}`,
    //         method: "DELETE",
    //         user
    //     })
    //     .then((res) => res.json())
    //     .then((data) => {
    //         toast.success('Compte supprimé')
    //         navigate('/login')
    //     })
    //     .catch((err) => {
    //         console.error(err)
    //         toast.error('Erreur lors de la suppression du compte')
    //     })
    // }
    
    const isAuthorized = userViewed?.id === user.user.sub || userViewed?.societe?.id === user.user.user_metadata.societe ? true : false

    const renderTypeUserViewed = (userViewed) => {
        if ( userViewed?.type_marchandise ) {
                return <>
                    <div className="info-profil-carrier-part">  
                        <h2>Informations Chauffeur:</h2>
                        <p className='info-data-profile'>Numéro de SIRET: {userViewed?.numero_siret}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Numéro de TVA: {userViewed?.numero_tva}</p>
                        {/* <div className='separation-thick'></div>
                        <p className='info-data-profile'>Certificat Instruction: {userViewed?.certificat_instruction}</p> */}
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Type Marchandises transportées: {userViewed?.type_marchandise}</p>
                        { (isAuthorized) && <>
                            <div className='separation-thick'></div>
                            <button onClick={ () => setUpdate(['carrier', userViewed, 'transporteur']) } className='modify-btn-wheel'><img src={ settingIcon } alt="setting-icon" className='icon-update-btn'/>Modifier Transporteur</button>
                        </>
                        }
                    </div>
                    <div className="info-profil-carrier-society-part">
                        <h2>Informations Société:</h2>
                        <p className='info-data-profile'>Nom : {userViewed?.societe.nom}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Téléphone Référant : {userViewed?.societe.telephone_referant}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Mail Référant : {userViewed?.societe.mail_referant}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Iban : {userViewed?.societe.iban}</p>
                    </div>
                </>
        } else if ( userViewed?.societe ) {

            return <div className="info-profil-admin">
                    <h2>Informations Société:</h2>
                    <p className='info-data-profile'>Nom : {userViewed?.societe.nom}</p>
                    <div className='separation-thick'></div>
                    <p className='info-data-profile'>Téléphone Référant : {userViewed?.societe.telephone_referant}</p>
                    <div className='separation-thick'></div>
                    <p className='info-data-profile'>Mail Référant : {userViewed?.societe.mail_referant}</p>
                    <div className='separation-thick'></div>
                    <p className='info-data-profile'>Iban : {userViewed?.societe.iban}</p>
                    { (userViewed.societe.id === user.user.user_metadata.societe) && <>
                        <div className='separation-thick'></div>
                        <button onClick={ () => setUpdate(['company', userViewed.societe, 'societe']) } className='modify-btn-wheel'><img src={ settingIcon } alt="setting-icon" className='icon-update-btn'/>Modifier Société</button>
                    </>
                    }
                </div>
        } else {
            return <div className="info-profil-create-mission">
                <LittleButton text={ "Ajouter Mission" } onClick={ navigate } valueClick={ '/create/mission/0' } type={ "button" } background={ "#98369B"} color={ "#FFF" }/>
            </div>
        }
    }

    return <div className="container-profil">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <h1 className='title-basic'>Profile</h1>
        <div className='img-modify-profil'>
            <div className="container-img-back-btn-profile">
                <img src={ profilIcon } alt="avatar-user" className='img-profil'/>
                <BackButton />
            </div>
            <div className="info-profil">
                <div className="info-profil-all-information">
                    <div className="info-profil-basic">
                        <h2>Informations Générales:</h2>
                        <p className='info-data-profile'>Prénom : {userViewed?.prenom}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Nom : {userViewed?.nom}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Téléphone : {userViewed?.telephone}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Adresse mail : {userViewed?.mail}</p>
                        <div className='separation-thick'></div>
                        <p className='info-data-profile'>Role : {userViewed?.type_marchandise ? "Chauffeur" : userViewed?.societe ? "Gérant Société" : "Expéditeur"}</p>
                        { (userViewed?.id === user.user.sub || userViewed?.societe?.id === user.user.user_metadata.societe) && <>
                            <div className='separation-thick'></div>
                            <button onClick={ () => setUpdate(['basic', userViewed, 'profile']) } className='modify-btn-wheel'><img src={ settingIcon } alt="setting-icon" className='icon-update-btn'/>Modifier Profile</button>
                        </>
                        }
                    </div>
                    { renderTypeUserViewed(userViewed) }
                </div>
                {/* <div className='container-delete-btn'>
                    { (isAuthorized) && 
                        <LittleButton text={"Supprimer le compte"} onClick={ deleteAccount  } type={ "button" } background={ "#98369B"} color={ "#FFF" }/>
                    }
                </div> */}
            </div>
        </div>
        <div className="modify-data">
            { update && <UpdateData typeUpdate={ update[0] } dataUpdate={ update[1] } endPoint={ update[2] } cancel={ setUpdate } user={ user }/>
            }
        </div>
        { (!userViewed?.certificat_instruction && userViewed?.societe) &&
            (userViewed?.societe?.id === user.user.user_metadata.societe && <>
                <ManageCarrier society={ userViewed.societe } user={ user }/>
                <ManageTruck society={ userViewed.societe } user={ user }/>
            </>
            )
        }
        {
            <ProfileMR userViewed={ userViewed } user={ user }/>
        }
    </div>
}