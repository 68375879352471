import './backButton.css'

import { useNavigate } from 'react-router-dom';

import { RiArrowGoBackFill } from "react-icons/ri";

export default function BackButton() {
    const navigate = useNavigate()

    return (
        <button className="backButton" onClick={() => navigate(-1)}>
            <RiArrowGoBackFill size={30} color='white'/>
            <span className='text-btn-back'>Retour</span>
        </button>
    )
}