import "./textHome.css"


export default function TextHome(props)
{
    return <div className="text-container">
        <p className="text-home">
            {props.text.map((item, index) => (
                <span key={index} className={item.class}>
                    {item.text}
                </span>
            ))}
        </p>
    </div>
}