import StarRate from "../../components/starRate"
import Button from "../../components/buttons/button"

import { Toaster, toast } from "sonner"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import './successPaiement.css'

import { AuthData } from "../../utils/auth/AuthWrapper"
import fetchData from "../../utils/function/fetchData"

export default function SuccessPaiementPage () {

  const { user } = AuthData()
  const navigate = useNavigate()
  const [idOffreTransport, setIdOffreTransport] = useState('')
  const [dataMission, setDataMission] = useState({})

  useEffect(() => {
    setIdOffreTransport(window.localStorage.getItem('idMisison'))
    fetchData({
      endpoint: `/offre-transport/${idOffreTransport}`,
      user: user
    })
      .then((result) => {
        // if (user.user.sub !== result.created_by) {
        //   toast.error('Vous n\'êtes pas autorisé à accéder à cette page')
        //   navigate(`/profil/${user.user.sub}`)
        // }
        window.localStorage.removeItem('idMission')
        setDataMission(result)
      })
      .catch((error) => {
        toast.error('Erreur lors de la récupération de la mission')
        console.error(error)
      })
  }, [idOffreTransport, user, navigate])

  const handleSubmit = (e) => {
  
        e.preventDefault()
        const formData = new FormData(e.target)
        const rapport = formData.get('rapport')
        const note = formData.get('rating')
  
        if (!rapport || !note) {
            toast.error('Veuillez remplir tous les champs')
            return
        }
        const review = {
            "message": rapport,
            // "created_by": user.user.sub,
            "transporteur": dataMission.transporteur,
            note: Number(note)
        }
  
        const updatedMission = {
            "fini": true
        }
  
        fetchData({
            endpoint: `/offre-fret/${dataMission.offre_fret.id}`, 
            method:'PATCH', 
            body: updatedMission, 
            user: user
        })
          .then(() => {
              fetchData({
                  endpoint: `/avis`, 
                  method: 'POST', 
                  body: review,
                  user: user
              })
                  .then(() => {
                      navigate(`/profil/${user.user.sub}`)
                      toast.success('Avis envoyé')
                  })
                  .catch((error) => {
                      toast.error('Erreur lors de l\'envoi de l\'avis')
                      console.error(error)
                  })
          })
        .catch((error) => {
            toast.error('Erreur lors de la mise à jour de la mission')
            console.error(error)
        })
  }

  console.log("dataMission:", dataMission)

  return <div className="container-success-paiement-page">
    <Toaster position="top-center" richColors closeButton={ true }/>
    <h1 className="title-success-paiement">Paiement Résussi!</h1>
    <p className="text-success-paiement">Merci pour votre confiance. Vous recevrez un email de confirmation.</p>
    <p className="text-success-paiement">Veuillez maintenant effectuer un virement bancaire d'un montant de { (dataMission?.prix * 0.98).toFixed(1) }€ à l'Iban : { dataMission?.profile?.iban }</p>
    <p className="text-success-paiement">Une fois ceci fait, vous pouvez maintenant remplir le rapport de mission.</p>
    <div className="rapport-mission">
          <h1 className='title-basic'>Rapport :</h1>
          <form className='rapport-mission' onSubmit={ (e) => handleSubmit(e) }>
              <textarea className='rapport' placeholder="Ce rapport servira d'avis au transporteur pour ses prochaines missions..." name='rapport'></textarea>
              <StarRate />
              <Button text={ "Envoyer Rapport" } typeButton={ "submit" }/>
          </form>
    </div>
  </div>
}
