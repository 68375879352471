
const url_API_Adresse_Gouv = 'https://api-adresse.data.gouv.fr/search/?q='

export default function submitAdresseCallAPI (inputAdresseAPICall, setResultsAdresse, setIsLoading, setError) {
    if (inputAdresseAPICall) {
        setIsLoading(true);
        fetch(`${url_API_Adresse_Gouv}${inputAdresseAPICall}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setResultsAdresse(data.features)
            })
            .catch(error => {
                setError(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
}