import React from 'react';

import './style.css'

import ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";

import { AuthWrapper } from './utils/auth/AuthWrapper'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthWrapper />
    </BrowserRouter>
  </React.StrictMode>
)


