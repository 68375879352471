import { FaTruck } from "react-icons/fa";
import { Toaster, toast } from "sonner";

import fetchData from "../../../utils/function/fetchData";

import LittleButton from "../../buttons/littleButton";

import "./cardVehicule.css";

import { AuthData } from "../../../utils/auth/AuthWrapper";

export default function CardVehicule ({ vehicule }) {
    const { user } = AuthData()

    const deleteTruck = (id) => {
        const truckDeleted = fetchData({
            endpoint: `/vehicule/${id}`,
            method: "DELETE",
            user
        })

        truckDeleted
            .then(dataTruckDeleted => {
                console.log("dataTruckDeleted", dataTruckDeleted)
                toast.success('Véhicule supprimé')
                window.location.reload()
            }
            )
            .catch(error => {
                toast.error('Erreur lors de la suppression du véhicule')
                console.error(error)
            }
            )
    }

    return <div className="container-card-vehicule">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <FaTruck size={80}/>
        <p className='vehicule-info'>Type: { vehicule.type_vehicule }</p>
        <p className='vehicule-info'>{ vehicule.immatriculation }</p>
        <LittleButton background={ "#EA4040" } text={ "Supprimer" } color={ "white" } valueClick={ vehicule.id } onClick={ deleteTruck }/>
    </div>
}