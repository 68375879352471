import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "sonner";

import LittleButton from "../../buttons/littleButton";

import "./cardOffreTransportHistorique.css";

import LogoReload from "../../../assets/LogoReload.jpg";

import fetchData from "../../../utils/function/fetchData";
import { AuthData } from "../../../utils/auth/AuthWrapper";

export default function CardOffreTransportHistorique({ offre }) {
    const navigate = useNavigate();

    const { user } = AuthData()

    let shadow = "";
    let offreStatus = "";
    let borderColor = "";

    if (offre.accepte === false) {
        shadow = "0px 0px 14px 8px  #98369B";
        offreStatus = "Offre de transport en attente de validation...";
        borderColor = "#98369B";
    } else if (offre.accepte === true && offre.offre_fret.fini === false) {
        shadow = "0px 0px 14px 8px #2040A7";
        offreStatus = "Offre de transport acceptée par l'expéditeur.";
        borderColor = "#2040A7";
    } else if (offre.accepte === true && offre.offre_fret.fini === true) {
        shadow = "0px 0px 14px 8px  #26B726";
        offreStatus = "Offre de transport terminée.";
        borderColor = "#26B726";
    }

    const deleteOffreTransport = (id) => {
        fetchData({
            endpoint: `/offre-transport/${id}`,
            method: "DELETE",
            user
        })
            .then(() => {
                toast.success('Offre de transport supprimée')
                window.location.reload();
            })
            .catch(error => {
                toast.error('Erreur lors de la suppression de l\'offre de transport')
                console.error(error)
            })
    }

    return (
        <div className="container-card-offre-transport-historique" style={{ boxShadow: shadow, borderColor: borderColor }}>
            <Toaster position="top-center" richColors closeButton={true} />
            <img src={LogoReload} alt="logo-reload" className='logo-card-offre-transport-historique' />
            <LittleButton text={"Voir"} onClick={() => navigate(`/read/mission/${offre.offre_fret.id}`)} type={"button"} background={"#98369B"} color={"#FFF"} />
            {!offre?.offre_fret?.accepte && <LittleButton text={ "Annuler" } onClick={ () => deleteOffreTransport(offre.id) } type={"button"} background={"red"} color={"#FFF"}/>}
            <div className="container-card-offre-transport-historique-header">
                <h3 className="status-card-offre-transport-historique">{offreStatus}</h3>
                <ul className="container-card-offre-transport-historique-body">
                    <li className="info-card-offre-transport-historique">Prix: {offre.prix}€</li>
                    <li className="info-card-offre-transport-historique">Date de création : {new Date(offre.created_at).toLocaleDateString()}</li>
                </ul>
            </div>
        </div>
    );
}