import './cgvu.css'

export default function CGVU() {
    
        return (
            <div className="cgvu">
            <h1 className='title-cgvu'>Conditions Générales de Vente et d'Utilisation</h1>
            <h2 className='sub-title-cgvu'>Article 1 : Objet</h2>
            <p className='text-cgvu'>Les présentes conditions générales de vente (ci-après les "CGVU") régissent les relations contractuelles entre MONSIEUR FABRICE BOUBAKER et toute personne physique ou morale (ci-après l'"Utilisateur") souhaitant utiliser l'application Reload.</p>
            <p className='text-cgvu'>Les présentes conditions sont à lire attentivement avant de vous inscrire ou de commander des services sur le site. En vous inscrivant sur le site, vous acceptez les présentes conditions.</p>
            <p className='text-cgvu'>Si vous n'acceptez pas les conditions, veuillez ne pas utiliser le site.</p>
            <p className='text-cgvu'>La société Reload se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes conditions générales. Les Utilisateurs seront informés de toute modification par le biais de l'application.</p>
            <h2 className='sub-title-cgvu'>Article 2 : Acceptation des CGVU</h2>
            <p className='text-cgvu'>Toute utilisation de l'application Reload implique l'acceptation pleine et entière des présentes CGVU par l'Utilisateur. En cas de désaccord avec les CGVU, l'Utilisateur est invité à ne pas utiliser l'application.</p>
            <h2 className='sub-title-cgvu'>Article 3 : Accès au site</h2>
            <p className='text-cgvu'>Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d'une nécessité de maintenance.</p>
            <p className='text-cgvu'>En cas de modification, interruption ou suspension des services le site reload-goods.fr ne saurait être tenu responsable.</p>
            <h2 className='sub-title-cgvu'>Article 4 : Inscription</h2>
            <p className='text-cgvu'>Pour s'inscrire sur le site, l'utilisateur doit remplir le formulaire d'inscription en ligne.</p>
            <p className='text-cgvu'>Lors de cette inscription, l'utilisateur s'engage à fournir des informations exactes et sincères concernant son état civil et ses coordonnées, notamment son adresse email.</p>
            <p className='text-cgvu'>L'utilisateur est responsable de la mise à jour des informations fournies. Il lui est précisé qu'il peut les modifier en se connectant à son espace personnel.</p>
            <p className='text-cgvu'>Pour accéder aux services, l'utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qu'il aura choisis.</p>
            <p className='text-cgvu'>Tout utilisateur inscrit dispose d'un identifiant et d'un mot de passe. Ces derniers sont strictement personnels et confidentiels et ne devront en aucun cas faire l'objet d'une communication à des tiers sous peine de suppression du compte de l'utilisateur en cas de manquement à cette obligation.</p>
            <p className='text-cgvu'>Le site Reload se réserve le droit de refuser l'accès aux services, unilatéralement et sans notification préalable, à tout utilisateur ne respectant pas les présentes conditions générales d'utilisation.</p>
            <h2 className='sub-title-cgvu'>Article 5 : Services proposés</h2>
            <p className='text-cgvu'>L'application Reload permet la mise en relation de transporteurs et d'expéditeurs via des offres de fret. Les Utilisateurs ont la possibilité de renseigner diverses informations telles que leur numéro de téléphone, leur adresse email, et d'autres documents nécessaires à la mise en place des offres de fret.</p>
            <h2 className='sub-title-cgvu'>Article 6 : Commission</h2>
            <p className='text-cgvu'>L'utilisation de l'application Reload est soumise au paiement d'une commission sur chaque offre de fret réalisée via la plateforme. Cette commission est élevé à 2% du prix de l'offre de fret prélevée.</p>
            <h2 className='sub-title-cgvu'>Article 7 : Responsabilités</h2>
            <p className='text-cgvu'>Reload s'engage à mettre en œuvre tous les moyens nécessaires pour assurer le bon fonctionnement de l'application Reload. Toutefois, Reload ne saurait être tenue responsable des dommages directs ou indirects causés par l'utilisation de l'application.</p>
            <h2 className='sub-title-cgvu'>Article 8 : Données personnelles</h2>
            <p className='text-cgvu'>En utilisant l'application Reload, l'Utilisateur consent à la collecte et au traitement de ses données personnelles conformément à la politique de confidentialité de l'application.</p>
            <h2 className='sub-title-cgvu'>Article 9 : Droit applicable et juridiction compétente</h2>
            <p className='text-cgvu'>Les présentes CGVU sont soumises au droit français. En cas de litige, les tribunaux français seront seuls compétents.</p>
            <p className='text-cgvu'>En acceptant les présentes CGVU, l'Utilisateur reconnaît avoir pris connaissance de l'intégralité des dispositions des CGVU et les accepter sans réserve.</p>
            </div>
        )
    }