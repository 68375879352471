import './adresse.css'

import FindAdresse from '../findAdresse'
import DataMode from '../dataMode'

export default function Adresse( { action, adresseColis=null, type, number } )
{
    return <div className="container-adresse">
            <h3 className='title-info-mission'>Information { type }</h3>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Nom Societe "} typeData={ "text" } dataName={ `nom_societe-${type}-${number}` } data={ adresseColis?.nom_societe }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Nom Responsable "} typeData={ "text" } dataName={ `nom_responsable-${type}-${number}` } data={ adresseColis?.nom_responsable }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Mail Responsable "} typeData={ "email" } dataName={ `mail-${type}-${number}` } data={ adresseColis?.mail_responsable }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Téléphone Responsable "} typeData={ "tel" } dataName={ `telephone-${type}-${number}` } data={ adresseColis?.telephone_responsable }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Date "} typeData={ "date" } dataName={ `date-${type}-${number}` } data={ adresseColis?.date }/>
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Horraire "} typeData={ "time" } dataName={ `horraire-${type}-${number}` } data={ adresseColis?.date }/>
            <div className="separation-thick"></div>
            {
                action === 'read' ?
                    <DataMode mode={ action } field={ "Adresse "} typeData={ "text" } dataName={ `label-${type}-${number}` } data={ adresseColis?.adresse }/>
                    :<FindAdresse nameAdresse={`label-${type}-${number}`} updateAdresse={ [adresseColis?.adresse, adresseColis?.longitude, adresseColis?.latitude] }/>
            }
            <div className="separation-thick"></div>
            <DataMode mode={ action } field={ "Complément Adresse "} typeData={ "text" } dataName={ `precision-${type}-${number}` } data={ adresseColis?.complement }/>
    </div>
}