import './mentionsLegales.css'

export default function MentionsLegales() {

    return (
        <div className="mentions-legales">
            <h1 className='title-mentions-legales'>Mentions légales</h1>
            <p className='text-mentions-legales'>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site reload-goods.fr l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :</p>
            <h2 className='sub-title-mentions-legales'>Propriétaire : FABRICE BOUBAKER - SIRET 85077486000017 - 24 B AV DE NANCY 57260 DIEUZE</h2>
            <h2 className='sub-title-mentions-legales'>Responsable publication : Fabrice Boubaker - fboubaker@free.fr</h2>
            <p className='text-mentions-legales'>Le responsable publication est une personne physique ou une personne morale.</p>
            <h2 className='sub-title-mentions-legales'>Hébergeur de l'application : OVH RCS Roubaix - 2 rue Kellermann - 59100 Roubaix - France</h2>
        </div>
    )
}
