import fetchData from "../../utils/function/fetchData"

import { useState, useEffect } from 'react'
import { Chart as ChartJS } from 'chart.js/auto'
import { Line, Bar } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'

import './admin.css'

import { AuthData } from "../../utils/auth/AuthWrapper"

export default function Admin() {
    const [userData, setUserData] = useState([]);
    const [connectionData, setConnectionData] = useState([]);
    const [missionData, setMissionData] = useState([]);
    const [dateRange, setDateRange] = useState('weekly')

    const navigate = useNavigate()

    const { user } = AuthData()

    useEffect(() => {
        // const accountApplication = async () => {
        //     const data = await fetchData({
        //         endpoint: '/profile',
        //         method: 'GET',
        //         user
        //     })
        //     setConnectionData(data)
        //     setUserData("Users :", data)
        // }
        // accountApplication()
        if (!user || user.role !== 'admin') {
            return navigate('/*')
        }

        const offreTransport = async () => {
            const data = await fetchData({
                endpoint: '/offre-fret',
                user
            })
            console.log("Mission :", data)
            setMissionData(data)
        }
        offreTransport()
    }, [user])

    const handleDateRangeChange = (range) => {
        setDateRange(range);
        if (dateRange === 'weekly') {
            // setConnectionData(.weeklyConnections)
        } else if (dateRange === 'daily') {
            // setConnectionData(.dailyConnections)
        }else if (dateRange === 'monthly') {
            // setConnectionData(.monthlyConnections)
        }
    }

    return (
        <div className='container-admin'>
            <div className='section-admin-user'>
                <h2 className="title-basic">Utilisateurs de l'application:</h2>
                <div className='chart-container-user'>
                    <Bar 
                        data={{
                            labels: ["Transporteur", "Expediteur", "Administrateur", "Total"],
                            datasets: [
                                {
                                    label: "Nombre d'utilisateurs",
                                    data: [
                                        userData?.filter(user => user.societe).length,
                                        userData?.filter(user => user.role === "expediteur").length,
                                        userData?.filter(user => user.role === "admin").length,
                                        userData?.length
                                    ],
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.8)',
                                        'rgba(54, 162, 235, 0.8)',
                                        'rgba(255, 206, 86, 0.8)',
                                        'rgba(75, 192, 192, 0.8)',
                                        'rgba(153, 102, 255, 0.8)',
                                        'rgba(255, 159, 64, 0.8)'
                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        'rgba(153, 102, 255, 1)',
                                        'rgba(255, 159, 64, 1)'
                                    ],
                                    borderWidth: 1
                                }
                            ]

                        }}
                    />
                </div>
                <div className='scroll-menu'>
                    <h2>Liste des utilisateurs:</h2>
                    <div className='user-container'>
                        {userData.map((user) => (
                            <div key={user.id} className='user-item'>
                                <p>{user.name}</p>
                                <p>{user.email}</p>
                                <p>{user.role}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='section-admin-connexion'>
                <h2 className="title-basic">Connexions:</h2>
                <div className='chart-container-connexion'>
                    <Line
                        data={{
                            labels: connectionData.map((data) => data.date),
                            datasets: [
                                {
                                    label: "Nombre de connexions",
                                    data: connectionData.map((data) => data.value),
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.8)',
                                        'rgba(54, 162, 235, 0.8)',
                                        'rgba(255, 206, 86, 0.8)',
                                        'rgba(75, 192, 192, 0.8)',
                                        'rgba(153, 102, 255, 0.8)',
                                        'rgba(255, 159, 64, 0.8)'
                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        'rgba(153, 102, 255, 1)',
                                        'rgba(255, 159, 64, 1)'
                                    ],
                                    borderWidth: 1
                                }
                            ]
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                y: {
                                    beginAtZero: true
                                }
                            }
                        }}
                    />
                </div>
                <div className='date-buttons'>
                <button className="btn-date-admin" onClick={() => handleDateRangeChange('daily')} style={dateRange ==='daily' ? { background: '#4d4a4a'} : null}>Daily</button>
                    <button className="btn-date-admin" onClick={() => handleDateRangeChange('weekly')} style={dateRange ==='weekly' ? { background: '#4d4a4a'} : null}>Weekly</button>
                    <button className="btn-date-admin" onClick={() => handleDateRangeChange('monthly')} style={dateRange ==='monthly' ? { background: '#4d4a4a'} : null}>Monthly</button>
                </div>
            </div>

            <div className='section-admin-mission'>
                <h2 className="title-basic">Mission:</h2>
                <div className='chart-container-mission'>
                    <Bar 
                        data={{
                            labels: ["En attente", "En cours", "Terminé", "Total"],
                            datasets: [
                                {
                                    label: "Nombre de missions",
                                    data: [
                                        missionData?.filter(mission => mission.fini === false && !mission.offre_transport.some(offre_transport => offre_transport.accepte === true)).length,
                                        missionData?.filter(mission => (mission.fini === false && mission.offre_transport.some(offre_transport => offre_transport.accepte === true))).length,
                                        missionData?.filter(mission => mission.fini === true).length,
                                        missionData?.length
                                    ],
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.8)',
                                        'rgba(54, 162, 235, 0.8)',
                                        'rgba(255, 206, 86, 0.8)',
                                        'rgba(75, 192, 192, 0.8)',
                                        'rgba(153, 102, 255, 0.8)',
                                        'rgba(255, 159, 64, 0.8)'
                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        'rgba(153, 102, 255, 1)',
                                        'rgba(255, 159, 64, 1)'
                                    ],
                                    borderWidth: 1
                                }
                            ]

                        }}
                    />
                </div>
            </div>
        </div>
    )
}
