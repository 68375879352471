import fetchData from "../../../utils/function/fetchData"


export default function CreateMission( { event, user, nbColis }) {


    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)

    const missionData = {
        "delai": formData.get("delai"),
        "type_vehicule": formData.get("type_vehicule"),
        "montant_assurance": formData.get("montant_assurance"),
        "prix": formData.get("prix"),
    }

    const missionCreated = fetchData({  
        endpoint: `/offre-fret`,
        method: "POST",
        body: missionData,
        user
    })

    missionCreated.then(dataMissionCreated => {  
        for (let i = 0; i < nbColis; i++) {
            const colisData = {
                "nature": formData.get(`nature-${i}`),
                "poids": formData.get(`poids-${i}`),
                "dangereux":formData.get(`dangereux-${i}`) === "on" ? true : false,
                "recuperer_emballage": formData.get(`recuperer_emballage-${i}`) === "on" ? true : false,
                "hauteur": formData.get(`hauteur-${i}`),
                "longueur": formData.get(`longueur-${i}`),
                "largeur": formData.get(`largeur-${i}`),
                "volume": formData.get(`volume-${i}`),
                "metre_plancher": formData.get(`metre_plancher-${i}`),
                "gerbable": formData.get(`gerbable-${i}`) === "on" ? true : false,
                "id_offre_fret": dataMissionCreated.id
            }

            // Adresse de chargement création
            const adresseChaObject = {
                label: JSON.parse(formData.get(`label-chargement-${i}`)),
            }
            const adresseChargementData = {
                "adresse": adresseChaObject.label.properties.label,
                "complement": formData.get(`precision-chargement-${i}`),
                "nom_responsable": formData.get(`nom_responsable-chargement-${i}`),
                "telephone_responsable": formData.get(`telephone-chargement-${i}`),
                "mail_responsable": formData.get(`mail-chargement-${i}`),
                "date": new Date(formData.get(`date-chargement-${i}`) + " " + formData.get(`horraire-chargement-${i}`)).toISOString(),
                "prise": true,
                "latitude": adresseChaObject.label.geometry.coordinates[1],
                "longitude": adresseChaObject.label.geometry.coordinates[0]
            }
            
            // Adresse de déchargement création
            const adresseDechaObject = {
                label: JSON.parse(formData.get(`label-dechargement-${i}`)),
            }
            const adresseDechargementData = {
                "adresse": adresseDechaObject.label.properties.label,
                "complement": formData.get(`precision-dechargement-${i}`),
                "nom_responsable": formData.get(`nom_responsable-dechargement-${i}`),
                "telephone_responsable": formData.get(`telephone-dechargement-${i}`),
                "mail_responsable": formData.get(`mail-dechargement-${i}`),
                "date": new Date(formData.get(`date-dechargement-${i}`) + " " + formData.get(`horraire-dechargement-${i}`)).toISOString(),
                "prise": false,
                "latitude": adresseDechaObject.label.geometry.coordinates[1],
                "longitude": adresseDechaObject.label.geometry.coordinates[0]
            }
            const adresseDechargementCreated = fetchData({
                endpoint: `/adresse`,
                method: "POST",
                body: adresseDechargementData,
                user
            })

            adresseDechargementCreated.then(dataAdresseDechargementCreated => {
                colisData["adresse_livraison"] = dataAdresseDechargementCreated.id

                const adresseChargementCreated = fetchData({  
                    endpoint: `/adresse`,
                    method: "POST",
                    body: adresseChargementData,
                    user
                })
                adresseChargementCreated.then(dataAdresseChargementCreated => {
                    colisData["adresse_recuperation"] = dataAdresseChargementCreated.id

                    fetchData({
                        endpoint: `/colis`,
                        method: "POST",
                        body: colisData,
                        user
                    })    
                    
                }).catch(error => {
                    console.error(error)
                })
            }).catch(error => {
                console.error(error)
            })
        }
    }).catch(error => {
        console.error(error)
    })

}