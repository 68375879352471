import { useNavigate } from "react-router-dom";
import LittleButton from "../../buttons/littleButton";
import "./cardOffreFretHistorique.css";
import LogoReload from "../../../assets/LogoReload.jpg";

export default function CardOffreFretHistorique({ offre }) {
    const navigate = useNavigate();

    let shadow = "";
    let offreStatus = "";
    let borderColor = "";

    if (offre.fini === false && offre.offre_transport.length === 0) {
        shadow = "0px 0px 14px 12px #98369B";
        offreStatus = "Offre de fret en attente d'offre de transport...";
        borderColor = "#98369B";
    } else if (offre.fini === false && offre.offre_transport.length > 0) {
        shadow = "0px 0px 14px 12px #2040A7";
        offreStatus = "Offre de fret en cours.";
        borderColor = "#2040A7";
    } else if (offre.fini === true) {
        shadow = "0px 0px 14px 12px #26B726";
        offreStatus = "Offre de fret terminée.";
        borderColor = "#26B726";
    }

    return (
        <div className="container-card-offre-fret-historique" style={{ boxShadow: shadow, borderColor: borderColor }}>
            <img src={LogoReload} alt="logo-reload" className='logo-card-offre-fret-historique' />
            <LittleButton text={"Voir"} onClick={() => navigate(`/manage-offre-fret/${offre.id}`)} type={"button"} background={"#98369B"} color={"#FFF"} />
            <div className="container-card-offre-fret-historique-header">
                <h3 className="status-card-offre-fret-historique">{offreStatus}</h3>
                <ul className="container-card-offre-fret-historique-body">
                    <li className="info-card-offre-fret-historique">Prix: {offre.prix}€</li>
                    <li className="info-card-offre-fret-historique">Colis: {offre.colis?.length}</li>
                    <li className="info-card-offre-fret-historique">Date de création : {new Date(offre.created_at).toLocaleDateString()}</li>
                </ul>
            </div>
        </div>
    );
}
