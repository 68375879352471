import './dataMode.css'

export default function DataMode( { mode, field, data=null, typeData, dataName, unite } ) 
{

    let contentRenderData = null

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(); // Formats date as per locale
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString(); // Formats time as per locale
    };

    if ( mode === 'read' ) {

        let displayData = data;
        if (typeData === 'date') {
            displayData = formatDate(data);
        } else if (typeData === 'time') {
            displayData = formatTime(data);
        }
        contentRenderData =  <div className="data-mode">
            <h3>{ field }: </h3>{typeData==='checkbox' ? (<div style={{display:'flex', justifyItems:'flex-end'}}><input type={ typeData } checked={ data } className='check-box'/>{ unite }</div>) : (<p>{displayData} { unite }</p>)}
        </div>

    } else if ( mode === 'update' ) {

        let displayData = data;
        if (typeData === 'date') {
            displayData = data?.split('T')[0];
        } else if (typeData === 'time') {
            const timeString = new Date(data).toISOString().split('T')[1]; // Extracts time part
            displayData = timeString.slice(0, 5); // Gets HH:MM part
        } 

        contentRenderData = <label className='data-mode'>
            { field }
            <div className='inpu-span-data-mode' style={{display:'flex', alignItems:'center'}}>
                <input 
                    type={ typeData } 
                    name={ dataName }
                    defaultValue={ displayData}
                    defaultChecked={ data }
                    className={typeData === 'checkbox' ? 'check-box' : ''}
                    {...(typeData !== 'checkbox'  && { required: true })}
                />
                <span>{ unite }</span>
            </div>
        </label>

    } else if ( mode === 'create' ) {

        contentRenderData = <label className='data-mode'> 
            { field }
            <div className='inpu-span-data-mode' style={{display:'flex', alignItems:'center'}}>
                <input 
                    type={ typeData } 
                    name={ dataName }
                    className={typeData === 'checkbox' ? 'check-box' : ''}
                    // {...((typeData !== 'checkbox' || field !== 'Complément Adresse') && { required: true })}
                    {...(typeData !== 'checkbox' && { required: true })}
                    // {...(field !== 'Complément Adresse' && { required: true })}
                />
                <span>{ unite }</span>
            </div>
        </label>

    }

    return contentRenderData
}