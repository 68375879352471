import { FaUser } from "react-icons/fa";

import Button from '../../buttons/button'

import './cardTransporteur.css'

export default function CardTransporteur ({ transporteur }) {

    return <div className="container-card-transporteur-profile">
        <FaUser size={80}/>
        <p className='info-profile-carrier'>{ transporteur.prenom } { transporteur.nom }</p>
        <Button text={"Voir Profil"} link={`/profil/${transporteur.id}`} />
    </div>
}