import './updateData.css';

import LittleButton from '../buttons/littleButton';

import fetchData from "../../utils/function/fetchData"

import { Toaster, toast } from 'sonner';
import { useState } from 'react';

export default function UpdateData({ typeUpdate, dataUpdate, endPoint, cancel, user }) {
    const [certificat, setCertificat] = useState(null)

    const handleSubmit = (e) => {
        cancel(null)
        e.preventDefault()
        const form = e.target
        const formData = new FormData(form)
        const dataUpdated = {}
        formData.forEach((value, key) => {
            dataUpdated[key] = value
        })
        console.log(dataUpdated)
        fetchData({
            endpoint: `/${endPoint}/${dataUpdate.id}`,
            method: "PATCH",
            body: dataUpdated,
            user: user 
        }).then(response => {
            toast.success('Données modifiées')
            window.location.reload();
        }).catch(error => {
            toast.error('Erreur lors de la modification des données')
        })
    }

    const renderDataUpdate = (dataUpdate) => {
        switch (typeUpdate) {
            case 'carrier':
                console.log(dataUpdate)
                const certificatInstruction = fetchData({
                    endpoint: `/document`,
                    method: "POST",
                    body: {
                        "path": dataUpdate.certificat_instruction
                    },
                    user: user,
                })
                certificatInstruction.then(response => {
                    console.log("Response", response)
                    setCertificat(response)
                })
                console.log(certificatInstruction)
                return (<>
                    <label className='label-update-data'>
                        Numéro SIRET:
                        <input
                            type='text'
                            defaultValue={ dataUpdate.numero_siret }
                            name='numero_siret'
                        />
                    </label>
                    <label className='label-update-data'>
                        Numéro TVA:
                        <input
                            type='text'
                            defaultValue={ dataUpdate.numero_tva }
                            name='numero_tva'
                        />
                    </label>
                    {/* <label className='label-update-data'>
                        Certificat Instruction:
                        <input
                            type='text'
                            defaultValue={ certificat }
                            name='certificat_instruction'   
                        />
                    </label>      */}
                    <label className='label-update-data'>
                        Type Marchandises transportées: (séparé par des virgules)
                        <input
                            type='text'
                            defaultValue={ dataUpdate.type_marchandise }
                            name='type_marchandise'   
                        />
                    </label>     
                </> 
                )
            case 'basic':
                return (<>
                    <label className='label-update-data'>
                        Prénom:
                        <input
                            type='text'
                            defaultValue={dataUpdate.prenom}
                            name='prenom'
                        />
                    </label>
                    <label className='label-update-data'>
                        Nom:
                        <input
                            type='text'
                            defaultValue={dataUpdate.nom}
                            name='nom'
                        />
                    </label>
                    <label className='label-update-data'>
                        Téléphone:
                        <input
                            type='text'
                            defaultValue={dataUpdate.telephone}
                            name='telephone'
                        />
                    </label>
                    <label className='label-update-data'>
                        Adresse Mail:
                        <input
                            type='mail'
                            defaultValue={dataUpdate.mail}
                            name='mail'
                        />
                    </label>
                </>
                )
            case 'company':
                const certificatKbis = fetchData({
                    endpoint: `/document`,
                    method: "POST",
                    body: {
                        "path": dataUpdate.certificat_kbis
                    },
                    user: user,
                })
                certificatKbis.then(response => {
                    console.log("Response", response)
                    setCertificat(response)
                })
                return (<>
                    <label className='label-update-data'>
                        Nom:
                        <input
                            defaultValue={dataUpdate.nom}
                            name='nom'
                            type='text'
                        />
                    </label>
                    <label className='label-update-data'>
                        Téléphone Référant:
                        <input
                            defaultValue={dataUpdate.telephone_referant}
                            name='telephone_referant'
                            type='tel'
                        />
                    </label>
                    <label className='label-update-data'>
                        Mail Référant:
                        <input
                            defaultValue={dataUpdate.mail_referant}
                            name='mail_referant'
                            type='email'
                        />
                    </label>
                    {/* <label className='label-update-data'>
                        Certificat k bis:
                        <input
                            defaultValue={certificat}
                            name='certificat_kbis'
                            type='file'
                        />
                    </label> */}
                </>)
            default:
                return null
        }
    }

    return (
        <div className='update-data'>
            <Toaster position='top-center' richColors closeButton={ true }/>
            <h2 className='title-update-data-container'>Modification Informations:</h2>
            <form onSubmit={handleSubmit}>
                { renderDataUpdate(dataUpdate) }
                <div className='separation-thick'></div>
                <div className='container-btn-cancel-update'>
                    <LittleButton text={ "Sauvegarder Modification" } color="#FFF" background="green"/>
                    <LittleButton text={ "Annuler Modification" } color="#FFFFFF" background="#2040A7" onClick={ cancel } valueClick={ null } type={ "button"}/>
                </div>
            </form>
        </div>
    )
}

