import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Toaster, toast } from 'sonner'

import './manageMission.css'

import fetchData from "../../utils/function/fetchData"
import { AuthData } from "../../utils/auth/AuthWrapper"

import LittleButton from '../../components/buttons/littleButton'
import Paiement from '../../features/manageMission/stripePaiment/paiement'
import CardOffreTransport from '../../components/cards/cardOffreTransport'


export default function ManageMission()
{
    const { user } = AuthData()
    const { idMission } = useParams()
    const navigate = useNavigate()
    const [transporteurAccepte, setTransporteurAccepte] = useState('')
    const [dataMission, setDataMission] = useState({})

    const [isAuthorized, setIsAuthorized] = useState(true)


    useEffect(() => {
        async function loadData() {
            try {
                const result = await fetchData({
                    endpoint: `/offre-fret/${idMission}`,
                    user
                });
    
                if (user.user.sub !== result.created_by) {
                    setIsAuthorized(false);
                } else {
                    setIsAuthorized(true);
                    setDataMission(result);
                    // Process transporteurs here as 'result' is immediately available
                    result.offre_transport.some(transporteur => {
                        return transporteur.accepte && setTransporteurAccepte(transporteur.id);
                    });
                }
            } catch (err) {
                console.error("Error:", err);
            }
        }
        loadData();
    }, [idMission, user]);  // Include dependencies here

    const deleteMission = () => {
        fetchData({endpoint: `/offre-fret/${idMission}`, method: 'DELETE', user})
            .then(() => {
                toast.success('Offre de fret supprimée')
                navigate(`/profil/${user.user.sub}`)
            })
            .catch((error) => {
                toast.error('Erreur lors de la suppression de l\'offre de fret')
                console.error(error)
            })
    }

    if (!isAuthorized) {
        return (
            <div className="container-manage-mission">
                <h1>Vous n'êtes pas authorisé à acceder à cette page.</h1>
            </div>
        );
    }

    return <div className="container-manage-mission">
        <Toaster position='top-center' richColors closeButton={ true }/>
        <h1 className='title-basic'>Gestion Offre De Fret:</h1>
        <div className='separation-hor'></div>
        <div className="modify-mission">
            <h1 className='title-basic'>Modification Et Supression:</h1>
            <div className="delete-update-mission-btn">
                <LittleButton text={ "Voir" } onClick={ navigate } valueClick={ `/read/mission/${dataMission?.id}` } color="#FFF" background="green"/>
                <LittleButton text={ "Modifier" } onClick={ navigate } valueClick={ `/update/mission/${dataMission?.id}`} color="#FFF" background="#98369B"/>
                <LittleButton text={ "Supprimer" } onClick={ deleteMission }color="#FFF" background="red"/>
            </div>
        </div>
        <div className='separation-hor'></div>
        <div className="transporteur-mission">
            <h1 className='title-basic'>{dataMission?.offre_transport?.some(offre => offre.accepte ) ? "Chauffeur de cette mission :" : "Chauffeurs disponibles :" }</h1>
            {dataMission?.offre_transport?.length === 0 ? (
                <div className="no-offre-transport">
                    <h2 style={{color: '#fff', textAlign: 'center'}}>Aucune offre de transport pour cette mission.</h2>
                </div>
            ) : (
                <div className='container-list-item-card-offre-transport'>
                    {dataMission?.offre_transport?.map((transporteur, index) => {
                            return <CardOffreTransport key={index} offreTransport={transporteur} />;
                        })
                    }
                </div>
            )}
        </div>
        { dataMission.offre_transport?.some(transporteur => transporteur.accepte) && (<>
            <div className='separation-hor'></div>
            <div className="paiement-mission">
                <h1 className='title-basic'>Paiement :</h1>
                <p className='text-end-pay-mission'>Pour terminer la mission et y mettre fin, veuillez suivre les étapes suivantes : </p>
                <ol className='information-pay-mission'>
                    <li className='text-end-pay-mission'>Payer la commision à la plateforme reload qui prend 2% du prix de l'offre de transport soit :  { (dataMission.offre_transport[0].prix * 0.02).toFixed(1)  }€. Le paiment s'effectue via Stripe ci-dessous.</li>
                    <li className='text-end-pay-mission'>Faire un virement bancaire à la société du chauffeur d'un montant de 98% de l'offre de transport soit : { (dataMission.offre_transport[0].prix * 0.98).toFixed(1) }€. Le paiment s'effectue via l'Iban de la société qui sera affiché.</li>
                    <li className='text-end-pay-mission'>Attribuer une note et un avis au chauffeur engagé.</li>
                </ol>
                <Paiement id_offre_transport={transporteurAccepte} user={user} />
            </div>
        </>
        )}
    </div>
}