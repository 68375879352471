import "./CRUDMission.css"

import { AuthData } from "../../utils/auth/AuthWrapper"

import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Toaster, toast } from "sonner"

import CreateMission from "../../features/CRUDMission/createMission"
import MenuMission from "../../features/CRUDMission/menuMission"
import ReadMission from "../../features/CRUDMission/readMission"
import UpdateMission from "../../features/CRUDMission/updateMission"
import Button from "../../components/buttons/button"
import Mission from "../../features/CRUDMission/mission"

export default function CRUDMission()
{
    const { mode } = useParams()
    const { idMission } = useParams()
    const { user } = AuthData()

    const navigate = useNavigate()

    const [mission, setMission] = useState({})
    const [colis, setColis] = useState([[]])
    const [userCreated, setUserCreated] = useState(null)
    const [titleMode, setTitleMode] = useState("")
    const[authoUpdated, setAuthoUpdated] = useState(true)

    useEffect(() => {
        switch (mode) {
            case "create":
                setTitleMode("CREER")
                break;
            case "update":
                setTitleMode("MODIFIER")
                ReadMission({
                    idOffreFret: idMission,
                    user,
                    setMission,
                    setColis,
                    setUserCreated
                })
                console.log(mission)
                if ( user.user.sub ===  !mission?.created_by)
                {
                    setAuthoUpdated(false)
                }
                break;
            case "read":
                setTitleMode("Lecture")
                ReadMission({
                    idOffreFret: idMission,
                    user,
                    setMission,
                    setColis,
                    setUserCreated
                })
                break;
            default:
                break;
        }
    }, [mode, idMission, user])

    /**
     * Expediteur Part :
     */

    // CRUD mission :
    const handleSubmit = (e) => {
        switch (mode) {
            case "create":
                try {
                    CreateMission({
                        event: e,
                        nbColis: colis.length,
                        user
                    })
                    toast.success('Offre de fret créée avec succès')
                    return (
                        navigate(`/profil/${user.user.sub}`)
                    )
                } catch (error) {
                    toast.error('Erreur lors de la création de l\'offre de fret')
                    console.error(error)
                }
                break;

            case "update":
                try {
                    UpdateMission({
                        event: e,
                        missionUpdate: mission,
                        user
                    })
                    toast.success('Offre de fret modifiée avec succès')
                    return (
                        navigate(`/profil/${user.user.sub}`)
                    )
                } catch (error) {
                    toast.error('Erreur lors de la modification de l\'offre de fret')
                    console.error(error)
                }
                break;

            default:
                break;
        }
    }

    return <div className="container-mode-mission">
        { authoUpdated ? <> 
            <Toaster position='top-center' richColors closeButton={ true }/>
            <h1 className="title-basic">{ titleMode } OFFRE DE FRET</h1>
            <div className="separation-hor"></div>
            <div className="main-mode-mission">
                <MenuMission 
                    mission={ mission }
                    colis={ colis }
                    setColis={ setColis }
                    mode={ mode } 
                    userCreation={ userCreated }
                />
                <Mission action={ mode } mission={mission} colis={ colis } handleSubmit={ handleSubmit }/>
            </div>
        </> :<>
            <h1 className="title-basic">Vous n'avez pas les droits pour modifier cette offre de fret.</h1>
            <Button text={ "Retour" } link={ `/profil/${user.user.sub}` }/>
        </>
        }
    </div>
}