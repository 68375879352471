import './cardOffreTransport.css'

import LittleButton from "../../buttons/littleButton";

import { FaUser } from "react-icons/fa";
import { Toaster, toast } from 'sonner';
import { useNavigate } from "react-router-dom";

import { AuthData } from '../../../utils/auth/AuthWrapper';
import fetchData from '../../../utils/function/fetchData';

export default function CardOffreTransport({ offreTransport }) {

    const navigate = useNavigate()

    const { user } = AuthData()

    let shadow = "";
    let color = "";

    if ( offreTransport.accepte ) {
        shadow = "0px 0px 14px 12px #98369B";
        color = "#98369B";
    } else {
        shadow = "0px 0px 14px 12px #2040A7";
        color = "#2040A7";
    } 
    
    const accepter = () => {
        fetchData({
            endpoint: `/offre-transport/${offreTransport.id}`, 
            method: 'PATCH', 
            body: { 
                accepte: true 
            },
            user
        })
            .then(() => {
                window.location.reload()
                toast.success('Offre de transport acceptée')
            })
            .catch((error) => {
                toast.error('Erreur lors de l\'acceptation de l\'offre de transport')
                console.error(error)
            })
    }

    const refuse = () => {
        fetchData({
            endpoint: `/offre-transport/${offreTransport.id}`, 
            method: 'DELETE', 
            user
        })
            .then(() => {
                window.location.reload()
                toast.success('Offre de transport refusée')
            })
            .catch((error) => {
                toast.error('Erreur lors du refus de l\'offre de transport')
                console.error(error)
            })
    }

    return <div className="container-card-offre-transport" >
        <Toaster position='top-center' richColors closeButton={ true }/>
        <FaUser size={100} className='user-icon-card-offre-transport'color='#F2F4F9'/>
        <div className="shape-bg" style={{'backgroundColor':color}}></div>
        <div className="card-offre-transport-data">
            <p className='card-offre-transport-data-text'>Proposer le: {new Date(offreTransport.created_at).toLocaleDateString()}</p>
            <p className='card-offre-transport-data-text'>Pour un montant de: {offreTransport.prix}€</p>
        </div>
        <div className='card-offre-transport-button'>
            <LittleButton text={"Voir Le Profil"} onClick={() => navigate(`/profil/${offreTransport.transporteur}`)} type={"button"} background={"#2040A7"} color={"#FFF"} />
            {!offreTransport.accepte && <>
                    <LittleButton text={"Accepter"} onClick={ accepter } type={"button"} background={"#26B726"} color={"#FFF"} />
                    <LittleButton text={"Refuser"} onClick={ refuse } type={"button"} background={"#EA4040"} color={"#FFF"} />
                </>
            }
        </div>
    </div>
}