import { useState } from 'react';
import { Toaster, toast } from 'sonner';

import { supabase } from '../../../services/supabase/supabaseClient';

import './passwordReset.css';

export default function PasswordReset ()
{
    const [resetPassword, setResetPassword] = useState(false)
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        const email = e.target.email.value;
        // const redirectTo = process.env.REACT_APP_BASE_URL;
        try {
            const { resetData, error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: `https://reload-goods.fr/reset-password` });
            if (error) {
                throw new Error(error.message);
            }
            setSuccess(true);
            setError(null);
            setResetPassword(false);
            toast.success('Regardez votre email pour le lien de réinitialisation du mot de passe');
        } catch (error) {
            setSuccess(false);
            setError(error.message);
            toast.error(error.message);
        }
    };

    const renderComposent = () => {
        if (resetPassword) {
            return (
                <form onSubmit={ handleResetPassword } id='form-send-email-reset' className='form-send-email-reset'>
                    <input
                        type="email"
                        placeholder="Email"
                        name='email'
                    />
                    <button type='submit' className='btn-reset-true' form='form-send-email-reset'>Envoyer Email</button>
                </form>
                );
        } else {
            return (
                <div className='container-reset-passwrod-btn'>
                    <p className='sentence-reset'>Mot de passe oublié ? </p><button onClick={ () => setResetPassword(true) } className='btn-reset-true'>Oui</button>
                </div>
            )
        }
    }

    return (
        <div>
            <Toaster position='top-center' richColors closeButton={ true }/>
            { renderComposent() }
            { success && <p className='sentence-success'>Regardez vos emails</p> }
            { error && <p style={{color:'white'}}>{ error }</p> }
        </div>
    );
};