import fetchData from "../../../utils/function/fetchData";

export default function ReadMission({ idOffreFret, user, setMission, setUserCreated, setColis}) {

    const loadData = async () => {
        try {
            const result = await fetchData({
                endpoint: `/offre-fret/${idOffreFret}`,
                user
            });
            const dataMission = result;
            const updateColisAddresses = async (colis) => {
                return Promise.all(colis.map(async (colisItem) => {
                    const adresseChargementColis = await fetchData({
                        endpoint: `/adresse/${colisItem.adresse_recuperation}`,
                        user
                    });
                    const adresseDechargementColis = await fetchData({
                        endpoint: `/adresse/${colisItem.adresse_livraison}`,
                        user
                    });

                    return {
                        ...colisItem,
                        adresse_recuperation: adresseChargementColis,
                        adresse_livraison: adresseDechargementColis
                    };
                }));
            };

            const updatedColis = await updateColisAddresses(dataMission.colis);
            setColis(updatedColis);

            const userCreated = await fetchData({
                endpoint: `/profile/${dataMission.created_by}`,
                user
            });

            setMission(dataMission);
            setUserCreated(userCreated);
        } catch (err) {
            console.error("Error:", err);
        }
    };

    loadData();

}